import {toast} from 'react-toastify';

import {
  FETCH_PRODUCTS,
  ADD_PRODUCT,
  DELETE_PRODUCT,
  UPDATE_PRODUCT,
  stateNames,
  SET_TO_LOADING,
  ERROR,
  apiUrl
} from './masterReducer'

const baseUrl = apiUrl + "/products";

export const fetchProducts = () => async (dispatch) => {
  try {
    dispatch({type: SET_TO_LOADING, setLoad: stateNames.products})
    const response = await fetch(baseUrl);
    const jsonData = await response.json();
    dispatch({type:FETCH_PRODUCTS, products: jsonData});

  } catch (err) {
    //console.log(err)
    dispatch({type:ERROR, message: err.message});
  }
};

export const addProduct = (product) => async (dispatch) => {
  try {
    dispatch({type:SET_TO_LOADING, setLoad: stateNames.products});
    const response = await fetch(baseUrl,
        {
          method: "POST",
          headers:
            {
              "jwtToken": localStorage.jwtToken,
              "Content-Type": "application/json"
            },
          body: JSON.stringify(product)
        })
    const jsonData = await response.json();
    dispatch({type:ADD_PRODUCT, product: jsonData});
    toast.success("Uusi tuote lisätty");
  } catch (err) {
    dispatch({type:ERROR, message: err.message});
  }
};

export const deleteProduct =  (product_id) => async (dispatch) => {
  try {
    dispatch({type:SET_TO_LOADING, setLoad:stateNames.products});
    await fetch(`${baseUrl}/${product_id}`, 
      {
        method: 'DELETE',
        headers:
          {
            "jwtToken": localStorage.jwtToken
          }
      }
    );
    dispatch({type:DELETE_PRODUCT, product_id: product_id});
    toast.success("Tuote poistettu");
  } catch (err) {
    dispatch({type:ERROR, message: err.message});
  }
};

export const updateProduct = (product) => async (dispatch) => {
  try {
    dispatch({type:SET_TO_LOADING, setLoad: stateNames.products});
    await fetch(`${baseUrl}/${product.product_id}`,
      {
        method: "PUT",
        headers: {
          "jwtToken": localStorage.jwtToken,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(product)
      }
    );
    dispatch({type:UPDATE_PRODUCT, product: product});
    toast.success("Tuote päivitetty!");
  } catch (err) {
    dispatch({type:ERROR, message: err.message});
  }
};