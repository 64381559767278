import React, {Fragment, useState} from 'react';
import { Link } from 'react-router-dom';
import {Button} from 'react-bootstrap';
import { toast } from 'react-toastify';
import {login} from '../reducers/authActionCreators';
import {useDispatch} from '../store';

toast.configure();

const Login = () => {
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState({
    email: "",
    password: ""
  })

  const {email, password} = inputs;

  const onChange = (e) => {
    setInputs({...inputs, [e.target.name] : e.target.value});
  }

  const onSubmitForm = (e) => {
    e.preventDefault();
    try {
      const info = {email, password};
      dispatch(login(info));
    } catch (err) {
      console.error(err.message);
    }
  }

  return (
    <Fragment>
      <h1 className="text-center my-5">Kirjautuminen</h1>
      <form onSubmit={e => onSubmitForm(e)}>
          <input 
            type="email"
            name="email"
            placeholder="Sähköpostiosoite"
            className="form-control my-3"
            value={email}
            onChange={(e) => onChange(e)}
          />
          <input 
            type="password"
            name="password"
            placeholder="Oma salasana"
            className="form-control my-3"
            value={password}
            onChange={(e) => onChange(e)}
          />
          <Button 
            variant="dark shadow-sm"
            type="submit" 
            className="my-3 btn btn-dark btn-block"
          >
            Kirjaudu
          </Button>
      </form>
      <Link to="/register">
        <Button 
          variant="outline-dark my-3">
            Rekisteröidy
        </Button>
      </Link>
      <Link to="/forgot">
        <Button 
          className="float-right"
          variant="outline-dark my-3">
            Unohditko salasanasi?
        </Button>
      </Link>   
      
    </Fragment>
  );
};

export default Login;