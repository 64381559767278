import React from 'react';
import { Tabs, Tab} from 'react-bootstrap';
import HostViewUsers from './HostViewUsers';
import HostViewStats from './HostViewStats';
import ProductStats from './ProductStats';
import HostViewProducts from './HostViewProducts';
import HostSettings from './HostSettings';

const HostView = () => {
  return (
  <div style={{backgroundColor:"lightgray"}}>
    <Tabs
      defaultActiveKey="users"
      className="flex-nowrap d-bloc justify-content-end align-right"
      >
      <Tab id="hostTab" eventKey="users" title="Käyttäjät">
        <HostViewUsers />
      </Tab>
      <Tab id="hostTab" eventKey="products" title="Tuotteet">
        <HostViewProducts />
      </Tab>
      <Tab id="hostTab" eventKey="stats" title="Tilastoja">
        <HostViewStats/>
      </Tab>
      <Tab id="hostTab" eventKey="settings" title="Maksukehote">
        <HostSettings />
      </Tab>
    </Tabs>
  </div>
  )}

export default HostView;