import React, {Fragment, useState} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {useTrackedState, useDispatch} from '../store';
import {deleteUser} from '../reducers/userListActionCreators';

const DeleteUserButton = ({user}) => {
  const {spikes, hosts} = useTrackedState();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const userIsHost = hosts.map(h => h.host_id).includes(user.user_id);
  const userHasSpikes = (spikes.filter(s => s.spikee_id === user.user_id).length > 0);

  const handleDeleteUser = () => {
    setShowModal(false);
    try {
        dispatch(deleteUser(user.user_id, user.email));
    } catch (err) {
      console.error(err.message);
    }
  };
  
  return (
    <Fragment>
    <Button 
      disabled={userHasSpikes || userIsHost}
      className='m-3'
      variant={userHasSpikes || userIsHost ? "outline-secondary" : 'danger shadow-sm' }
      title={userHasSpikes || userIsHost ? 
        "Ennen käyttäjän poistamista piikkihistorian tulee olla pyyhitty ja Isäntä-status poistettu" 
        : null}
      onClick={() => setShowModal(true)}
      >
        <svg width="24" height="24" viewBox="0 0 16 16" class="bi bi-person-x-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6.146-2.854a.5.5 0 0 1 .708 0L14 6.293l1.146-1.147a.5.5 0 0 1 .708.708L14.707 7l1.147 1.146a.5.5 0 0 1-.708.708L14 7.707l-1.146 1.147a.5.5 0 0 1-.708-.708L13.293 7l-1.147-1.146a.5.5 0 0 1 0-.708z"/>
        </svg>
    </Button>
    <Modal show={showModal} onHide={() => setShowModal(false)}>
    <Modal.Header closeButton>
      <Modal.Title>
        Käyttäjän poistaminen
      </Modal.Title>
    </Modal.Header>
      <Modal.Body>
  Haluatko varmasti poistaa käyttäjän {user.name}? (Käyttäjälle lähetetään vahvistusviesti poistamisesta.)
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger shadow-sm"
          onClick={() => handleDeleteUser()}
        >
          Varmista
        </Button>
      </Modal.Footer>
  </Modal>
  </Fragment>
  )
};

export default DeleteUserButton;