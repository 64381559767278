/* Using multiple (async) reducers with combineReducers 
doesn't play nice with the useThunkReducer plugin. 
That is why all there is one large master reducer to handle
all actions. For readability, the action-creator functions 
are in their own files*/

export const SET_TO_LOADING = 'SET_TO_LOADING';
export const ERROR = 'ERROR';
export const WIPE_ERROR = 'WIPE_ERROR';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const SWITCH_CATEGORY_RANKS = 'SWITCH_CATEGORY_RANKS';
export const RENAME_CATEGORY = 'RENAME_CATEGORY';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';

export const FETCH_USERLIST = 'FETCH_USERLIST';
export const UPDATE_USER_EMAIL = 'UPDATE_USER_EMAIL';
export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD';
export const DELETE_USER = 'DELETE_USER';

export const FETCH_HOSTS = 'FETCH_HOSTS';
export const DELETE_HOST = 'DELETE_HOST';
export const ADD_HOST = 'ADD_HOST';

export const FETCH_SPIKES = 'FETCH_SPIKES';
export const ADD_SPIKE = 'ADD_SPIKE';
export const CLEAR_SPIKES = 'CLEAR_SPIKES';
export const CHANGE_CREDIT = 'CHANGE_CREDIT';
export const CHARGE_MULTIPLE_USERS = 'CHARGE_MULTIPLE_USERS';

export const FETCH_SETTINGS = 'FETCH_SETTINGS';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const SEND_MAIL = 'SEND_MAIL';

export const VALIDATE_USER_TOKEN = 'VALIDATE_USER_TOKEN';

console.log(process.env.NODE_ENV)
//export const apiUrl = 'https://pk.epiikki.fi/api'

export const apiUrl = 
  process.env.NODE_ENV === 'production' ? 
    process.env.REACT_APP_PROD_API_URL 
  : 
    process.env.REACT_APP_DEV_API_URL;

export const stateNames = {
  products: 'prouducts',
  userList: 'userList',
  spikes: 'spikes',
  hosts: 'hosts',
  settings: 'settings',
  currentUser: 'currentUser',
  categories: 'categories'
};

export const initialState = {
  products: [],
  categories: [],
  userList: [],
  spikes: [],
  hosts: [],
  settings:{
    bank_account:'', 
    reference:'', 
    message:'',
    payee:'',
    title: ''
  },
  currentUser: {
    isLogged: false, 
    user_id:'', 
    name:'', 
    email: '',
    isAdmin:false
  },
  loading: {
    products: false,
    userList: false,
    spikes: false,
    hosts: false,
    currentUser: false,
    settings: false,
    categories: false
  },
  error: 
  {
    flag: false,
    message: ''
  }
}

export const masterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TO_LOADING:
      return {...state,
        loading: {...state.loading, 
          [action.setLoad]: true
        }
      }
    case ERROR:
      return {...state, 
        loading: initialState.loading,
        error:{flag:true, message:action.message}
      };
    case WIPE_ERROR:
      return {...state,
        error: {
          flag: false,
          message: ''
        }
      };
    case LOGIN:
      return {...state, 
        loading: {...state.loading, currentUser:false}, 
        currentUser: action.currentUser
      };
    case LOGOUT:
      return {...state, 
        currentUser: {name:'', user_id:'', email:'', isLogged:false, isAdmin:false}
      };
    case FETCH_PRODUCTS:
      return {...state, 
        loading: {...state.loading, products:false},
        products: action.products  
      };
    case ADD_PRODUCT:
      return {...state,
        loading: {...state.loading, products:false},
        products: state.products.concat(action.product)
      };
    case DELETE_PRODUCT:
      return {...state,
        loading: {...state.loading, products:false},
        products: state.products.filter(p => p.product_id !== action.product_id)
      };
    case UPDATE_PRODUCT:
      return {...state,
        loading: {...state.loading, products:false},
        products: state.products.map(
          p => 
            p.product_id === action.product.product_id ? 
            action.product : p
        )
      };
    case FETCH_CATEGORIES:
      return {...state,
        loading: {...state.loading, categories:false},
        categories: action.categories
      };
    case SWITCH_CATEGORY_RANKS:
      return {...state,
        loading: {...state.loading, categories:false},
        categories: state.categories.map(cr => {
          if (cr.category_id === action.categories_to_switch[0].category_id) {
            return {...cr, rank: action.categories_to_switch[1].rank};
          } else if (cr.category_id === action.categories_to_switch[1].category_id) {
            return {...cr, rank: action.categories_to_switch[0].rank};
          } else {
            return cr;
          }
        })
      };
    case RENAME_CATEGORY:
      return {...state,
        loading: {...state.loading, categories:false, products:false},
        categories: state.categories.map(c => {
          return (c.category_id === action.category_id ? 
            {...c, category: action.new_name}
          : c
        
          )
        })
      };
    case DELETE_CATEGORY:
      return {...state,
        loading: {...state.loading, categories:false},
        categories: 
          state.categories.filter(cr => 
            cr.category_id !== action.category_id
          ).map(cr2 => 
            cr2.rank > action.rank ? 
              {...cr2, rank: cr2.rank-1} 
            : 
              cr2
          )
      };
    case ADD_CATEGORY:
      return {...state,
        loading: {...state.loading, categories:false},
        categories: [...state.categories, action.new_category]
      };
    case FETCH_USERLIST:
      return {...state,
        loading: {...state.loading, userList:false},
        userList: action.userList
      };
    case UPDATE_USER_EMAIL:
      return {...state,
        loading: {...state.loading, userList:false},
        userList: state.userList.map(u =>
          u.user_id === action.user_id ?
          {...u, email:action.email} : u
        )
      };
    case UPDATE_USER_PASSWORD:
      return {...state,
      loading: {...state.loading, userList:false}
      };
    case CHARGE_MULTIPLE_USERS:
      return {...state,
        loading: {...state.loading, userList:false},
        userList: state.userList.map(u => 
          action.users.map(u2 => u2.user_id).includes(u.user_id) ?
          action.users.find(u3 => u3.user_id === u.user_id) : u)
      };
    case DELETE_USER:
      return {...state,
        loading: {...state.loading, userList:false},
        userList: state.userList.filter(u => u.user_id !== action.user_id)  
      };
    case FETCH_HOSTS:
      return {...state,
        loading: {...state.loading, hosts:false},
        hosts: action.hosts
      };
    case ADD_HOST:
      return {...state,
        loading: {...state.loading, hosts:false},
        hosts: state.hosts.concat([action.host])
      };
    case DELETE_HOST:
      return {...state,
        loading: {...state.loading, hosts:false},
        hosts: state.hosts.filter(h => h.host_id !== action.host_id)
      };
    case FETCH_SPIKES:
      return {...state,
        loading: {...state.loading, 
          spikes:false,
          userList: false
        },
        spikes: action.spikes
      };
    case ADD_SPIKE: 
      return {...state,
        loading: {...state.loading, spikes:false},
        spikes: [...state.spikes, action.spikes]
      };
    case CHANGE_CREDIT: 
      return {...state,
        loading: {...state.loading, userList: false},
        userList: state.userList.map(u => 
          u.user_id === action.spikee_id ?
            {...u, credit : action.new_credit }
          :
            u
        ),
        currentUser: {...state.currentUser, credit: action.new_credit}
      };
    case CLEAR_SPIKES:
      return {...state,
        loading: {...state.loading, userList: false, spikes: false},
        spikes: state.spikes.filter(s => !action.spikee_ids.includes(s.spikee_id))
      };
    case FETCH_SETTINGS:
      return {...state,
        loading: {...state.loading, settings: false},
        settings: action.settings
      };
    case UPDATE_SETTINGS:
      return {...state,
        loading: {...state.loading, settings: false},
        settings: action.settings
      };
    case SEND_MAIL:
      return {...state,
        loading: {...state.loading, settings: false}
      };
    case VALIDATE_USER_TOKEN: {
      console.log(action);
      return {...state,
        currentUser: {...state.currentUser, name: action.name, user_id: action.user_id},
        loading: {...state.loading, currentUser: false}
      };
    }
    default:
      return {...state};
  }
}
