import React, {Fragment} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import {Spinner} from 'react-bootstrap';
import moment from 'moment';
import {nameFormatter, moneyFormatter, timestampFormatter} from './formatterFunctions';
import {useTrackedState} from '../store';
import './css/Piikki.css';

const ListUserSpikes = ({spikee=null, dateGiven = null, dateRangeGiven = null}) => {
  const {spikes, loading} = useTrackedState();

  var spikesToList = []


  //list spikes from a specific user
  if (spikee !== null) {
    spikesToList=spikes.filter(s => s.spikee_id === spikee.user_id);
  }
  // or from a specific date
  else if (dateGiven !== null) {
    spikesToList = spikes.filter(s => s.time.toDateString() === dateGiven);
  }
  // or from a range of previous days
  else if (dateRangeGiven !== null) {
    spikesToList = spikes.filter(s => moment(s.time).isAfter(moment().subtract(dateRangeGiven,'d')))
  }
  // default to listing all spikes
  else {
    spikesToList=spikes;
  }


  const columns = [
    {
      dataField: 'time',
      type: 'date',
      text: 'Aika',
      headerStyle: {backgroundColor:"#373433", color:"white", borderTop:0},
      sort: true,
      formatter: timestampFormatter
    }, {
    dataField: 'product_name',
    text: 'Tuote',
    headerStyle: {width: '20%', backgroundColor:"#373433", color:"white", borderTop:0},
    style: {overflowWrap:'break-word'}
  }, {
    dataField: 'spikee_name',
    text: 'Piikattava',
    hidden: (dateGiven === null) && (dateRangeGiven === null),
    headerStyle: {width: '20%', backgroundColor:"#373433", color:"white", borderTop:0},
    style: {overflowWrap:'break-word'},
    formatter: (cell, row, rowIndex) => {
      return (
        <Fragment>
            {nameFormatter(cell)}
        </Fragment>
      )
    }
  }, {
    dataField: 'spiker_name',
    text: 'Piikkaaja',
    headerStyle: {backgroundColor:"#373433", color:"white", borderTop:0},
    style: {overflowWrap:'break-word'},
    formatter: (cell, row, rowIndex) => {
      return (
        <Fragment>
          <div>
            {nameFormatter(cell + "   ")}
          {cell.isAdmin ? 
            <svg width="1.3em" height="1.3em" viewBox="0 0 16 16" class="bi bi-key-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <br/>
              <path fill-rule="evenodd" d="M3.5 11.5a3.5 3.5 0 1 1 3.163-5H14L15.5 8 14 9.5l-1-1-1 1-1-1-1 1-1-1-1 1H6.663a3.5 3.5 0 0 1-3.163 2zM2.5 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
            </svg> : null}
          </div>
        </Fragment>
      )
    }
  }, {
    dataField: 'change',
    type: 'number',
    text: 'Muutos',
    headerStyle: {width: '20%', backgroundColor:"#373433", color:"white", borderTop:0},
    style: {overflowWrap:'break-word'},
    headerAlign: "right",
    formatExtraData: moneyFormatter,
    formatter: (cell, row, rowIndex, moneyFormatter) => {
      return(
        <div align="right">
          {moneyFormatter(cell)}
        </div>
      )
    }
  }, {
    dataField: 'credit_after_spike',
    type: 'number',
    hidden: (dateGiven !== null) || (dateRangeGiven !== null),
    text: 'Uusi saldo',
    headerStyle: {width: '20%', backgroundColor:"#373433", color:"white", borderTop:0},
    headerAlign: "right",
    style: {overflowWrap:'break-word'},
    formatExtraData: moneyFormatter,
    formatter: (cell, row, rowIndex, moneyFormatter) => {
      return(
        <div align="right">
          {moneyFormatter(cell)}
        </div>
      )
    }
  },];

  const defaultSorted = [{
    dataField: 'time',
    order: 'desc'
  }] 

  return (
    <Fragment>
      {(loading.spikes || loading.userList) ? 
        <Spinner className="float-left" size="sm" animation="border" role="status"/>
       :
        <BootstrapTable 
          keyField="spike_id"
          className="mt-0"
          data={spikesToList} 
          columns={columns}
          defaultSorted={defaultSorted}
          id='spikes_table'
          bordered={false}
          bootstrap4={true}
          rowStyle={{backgroundColor:"whitesmoke"}}
          style={{borderStyle:"none"}}
          width="100%"
          condensed
        />
      }
      </Fragment>
    );
};

export default ListUserSpikes;