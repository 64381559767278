import React, {Fragment, useState} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {nameFormatter} from './formatterFunctions';
import {useDispatch} from '../store';
import {deleteProduct} from '../reducers/productsActionCreators'; 

const DeleteProduct = ({prod}) => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);

  const handleDelete = async () => {
    try {
      dispatch(deleteProduct(prod.product_id));
      setShowModal(false);
    } catch (err) {
      console.error(err.message)
    }
  }
  
  return (
    <Fragment>
      <Button
        onClick={() => setShowModal(true)} 
        variant="danger shadow-sm" 
        className="float-right"
      >
        <svg height="24px" viewBox="0 0 32 32" width="24px" >
          <path d="M20.377,16.519l6.567-6.566c0.962-0.963,0.962-2.539,0-3.502l-0.876-0.875c-0.963-0.964-2.539-0.964-3.501,0  L16,12.142L9.433,5.575c-0.962-0.963-2.538-0.963-3.501,0L5.056,6.45c-0.962,0.963-0.962,2.539,0,3.502l6.566,6.566l-6.566,6.567  c-0.962,0.963-0.962,2.538,0,3.501l0.876,0.876c0.963,0.963,2.539,0.963,3.501,0L16,20.896l6.567,6.566  c0.962,0.963,2.538,0.963,3.501,0l0.876-0.876c0.962-0.963,0.962-2.538,0-3.501L20.377,16.519z" fill="currentColor"/>
        </svg>
      </Button>
      
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Poista tuote</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        Haluatko varmasti poistaa tuotteen: {nameFormatter(prod.name)} ?
        </Modal.Body>
        <Modal.Footer>
            <Button variant="danger shadow-sm" onClick={handleDelete}>
              Poista
            </Button>
          </Modal.Footer>
      </Modal>
    </Fragment>
  )
};

export default DeleteProduct;