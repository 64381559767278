import {toast} from 'react-toastify';

import {
  ERROR,
  FETCH_SETTINGS,
  UPDATE_SETTINGS,
  SEND_MAIL,
  stateNames,
  SET_TO_LOADING,
  apiUrl
} from './masterReducer';

const baseUrl = apiUrl + "/mail"

export const fetchSettings = () => async (dispatch) => {
  try {
    dispatch({type:SET_TO_LOADING, setLoad:stateNames.settings});
    const response = await fetch(baseUrl, {
      headers: {
        "jwtToken" : localStorage.jwtToken
      }
    });
    const dataJson = await response.json();
    dispatch({type:FETCH_SETTINGS, settings:dataJson});
  } catch (err) {
    dispatch({type:ERROR, message:err.message});
  }
};

export const updateSettings = (info) => async (dispatch) => {
  try {
    dispatch({type:SET_TO_LOADING, setLoad:stateNames.settings});
    const response = await fetch(`${baseUrl}/settings`, 
      {
        method: 'POST',
        headers:
          {
            "jwtToken": localStorage.jwtToken,
            "Content-Type":"application/json"
          },
        body: JSON.stringify(info)
      } 
    );
    const dataJson = await response.json();
    dispatch({type:UPDATE_SETTINGS, settings:dataJson});
    toast.success("Maksukehote päivitetty");
  } catch (err) {
    dispatch({type:ERROR, message:err.message});
  }
}

export const sendMail = (title, mails) => async (dispatch) => {
  try {
    const body = {title:title, mails:mails};
    dispatch({type:SET_TO_LOADING, setLoad: stateNames.settings});
    const response = await fetch(`${baseUrl}/send`,
      {
        method: 'POST',
        headers:
          {
            "jwtToken": localStorage.jwtToken,
            "Content-Type":"application/json"
          },
        body: JSON.stringify(body)
      }
    );
    const dataJson = await response.json();
    if (response.status === 200) {
      dispatch({type:SEND_MAIL});
      return;
    }
    dispatch({type:ERROR, message:dataJson});
  } catch (err) {
    console.log(err.message);
  }
}