import React, {Fragment, useEffect,  useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter as Router,
  Switch, 
  Route, 
  Redirect
} from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './components/css/Piikki.css';
import {verify, wipeError} from './reducers/authActionCreators';
import {useTrackedState, useDispatch} from './store';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import Register from './components/Register';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';

toast.configure();

const App = () => {
  const {currentUser, error} = useTrackedState();
  const [appMountedFlag, setAppMountedFlag] = useState(false);
  const dispatch = useDispatch();

  function isAuth () {
    try {
      dispatch(verify(localStorage.jwtToken));
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    isAuth();
  }, []);

  useEffect(() =>{
    if (!appMountedFlag){ 
      setAppMountedFlag(true);
    }
    else if (error.flag) {
      toast.error(error.message);
      dispatch(wipeError());
    }
  }, [error]);

  return (
  <Fragment>
    <Router>
      <div className="container">
        <Switch>
          <Route 
            exact path="/"
            render={() =>
              <Redirect to="/login" />
            }
          />
          <Route 
            exact path="/login" 
            render={() => 
              !currentUser.isLogged ? (
                <Login />
              ) : (
                <Redirect to="/dashboard" />
                ) 
            } 
          />
          <Route 
            exact path="/register" 
            render={() => 
              !currentUser.isLogged ? (
                <Register />
              ) : (
              <Redirect to="/login"/>
              )
            } 
          />
          <Route 
            exact path="/dashboard" 
            render={() => 
              currentUser.isLogged ? (
              <Dashboard />
              ) : (
                <Redirect to="/login" />
              )
            } 
          />
          <Route 
            exact path="/forgot" 
            component={ForgotPassword}
          />
          <Route
            exact path="/reset_password/:token" 
            component = {ResetPassword}
          />
        </Switch>
      </div>
    </Router>
    <ToastContainer 
      autoClose={4000}
      position='top-center'
      limit={2} 
      closeButton={false}
      draggable
      pauseOnHover
      newestOnTop/>
  </Fragment>
  );
};
export default App;