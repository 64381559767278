import React, {Fragment, useState} from 'react';
import {Link} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import {useDispatch} from '../store';
import {register} from '../reducers/authActionCreators';
import { ERROR } from '../reducers/masterReducer';

const Register = () => {
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState({
    hyshys: "",
    email: "",
    password: "", 
    confirm_password: "",
    name: ""
  });

  const {hyshys, email, password, confirm_password, name} = inputs;

  const onChange = (e) => {
    setInputs({...inputs, [e.target.name] : e.target.value});
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();
    if (confirm_password !== password) {
      dispatch({type:ERROR, message:"Salasanat eivät täsmää"});
      return;
    }
    if (email === "" || password === "" || name === "" || hyshys === "" || confirm_password === "") {
      dispatch({type:ERROR, message:"Tyhjä kenttä"})
      return;
    }
    try {
      const info = {hyshys, email, name, password};
      //console.log(info);
      dispatch(register(info));

    } catch (err) {
      console.error(err.message);
    }

  };

  return (
    <Fragment>
      <h1 className="text-center my-5">Rekisteröityminen</h1>
      <form onSubmit={e => onSubmitForm(e)}>
      <input type="password" 
          name="hyshys" 
          placeholder="Hyshys" 
          className="form-control my-3"
          value={hyshys}
          onChange={e => onChange(e)}
        />

        <input type="text" 
          name="name" 
          placeholder="Nimi" 
          className="form-control my-3"
          value={name}
          onChange={e => onChange(e)}/>

        <input type="email" 
          name="email" 
          placeholder="Sähköpostiosoite" 
          className="form-control my-3"
          value={email}
          onChange={e => onChange(e)}
        />

        <input type="password" 
          name="password" 
          placeholder="Oma salasana" 
          className="form-control my-3"
          value={password}
          onChange={e => onChange(e)}/>

        <input type="password"
          name="confirm_password"
          placeholder="Vahvista salasana"
          className="form-control my-3"
          value={confirm_password}
          onChange={e => onChange(e)}/>

        <Button 
          variant="dark shadow-sm" 
          type="submit" 
          className="btn btn-dark btn-block"
        >
          Rekisteröidy
        </Button>
        <Link to="/login">
          <Button
            variant="outline-dark"
            className="my-3"
          >
            Kirjaudu
          </Button></Link>
      </form>
    </Fragment>
  );
};

export default Register;