import React, {Fragment, useState} from 'react';
import {Button, Modal, Form} from 'react-bootstrap';
import {useTrackedState, useDispatch} from '../store';
import {addSpike} from '../reducers/spikesActionCreators';
import { priceFormatter } from './formatterFunctions';
import squiggle from '../assets/squiggle1.png';
import hor_line from '../assets/hor_line.png';
import {round} from './formatterFunctions';

const compareCategories = (a, b) => {
  if (a.rank > b.rank) return 1;
  if (b.rank > a.rank) return -1;
  return 0;
}

const SpikeButtons = ({spikee, resetSelectedSpikee}) => {
  const {products, currentUser, categories} = useTrackedState();
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [massAmount, setMassAmount] = useState(null);
  const dispatch = useDispatch();

  const handleMassSpike = () => {
    setShowModal(false);
    try {
      const spike = {
        spiker_id: currentUser.user_id,
        spiker_name: currentUser.name,
        spikee_id: spikee.user_id,
        spikee_name: spikee.name,
        category_id: selectedProduct.category_id,
        category: categories.filter(c => 
          parseInt(c.category_id) === parseInt(selectedProduct.category_id))
          .map(c => c.category)[0],
        product_id: selectedProduct.product_id * 1000000, //mass spikes have a multiplier on the product id of one million
        product_name: `Massapiikkaus (${massAmount} x ${selectedProduct.name})`,
        price: selectedProduct.price * massAmount
      }
      dispatch(addSpike(spike));
      setMassAmount(null);
      if (spikee.user_id !== currentUser.user_id) resetSelectedSpikee();
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleSpike = async (e, prod) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const spike = {
        spiker_id: currentUser.user_id,
        spiker_name: currentUser.name,
        spikee_id: spikee.user_id,
        spikee_name: spikee.name,
        
        category_id: prod.category_id,
        category: categories.filter(c => 
          parseInt(c.category_id) === parseInt(prod.category_id))
          .map(c => c.category)[0],
        product_id: prod.product_id,
        product_name: prod.name,
        price: prod.price
      };
      dispatch(addSpike(spike));
      if (spikee.user_id !== currentUser.user_id) resetSelectedSpikee();
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <Fragment>
      {
        categories.sort(compareCategories)
        .filter(cat => products
          .filter(p => !p.hidden)
          .filter(p => p.category_id === cat.category_id).length > 0)
        .map(
          (cr, i) => (
            <div key={cr.category} align="center">
              {i > 0 ? 
                <table align="center">
                  <tbody>
                  <tr>
                  <td width="45%" align="center">
                    <img src={hor_line} width="100%" className="mx-1" style={{backgroundColor:"white"}} alt="" />
                  </td>
                  <td>
                  <img className="my-3 mx-2" src={squiggle} width={50} alt=""/>
                  </td>
                  <td width="45%" align="center">
                  <img src={hor_line} width="100%" className="mx-1" style={{backgroundColor:"white"}} alt=""/>
                  </td>
                  </tr>
                  </tbody>
                </table> : null}
            <h2 className="my-3"><b>{cr.category}</b></h2>
            {products
              .filter(p => p.category_id === cr.category_id)
              .filter(p => !p.hidden)
              .sort((p1, p2) => p1.name.localeCompare(p2.name))
              .map(prod =>
                    <Button 
                      variant="dark m-3 shadow-sm btn-ripple"  
                      className="btn btn-ripple"
                      key={prod.product_id}
                      onClick={(e) => handleSpike(e, prod)}
                      onContextMenu={(e) => {
                        e.preventDefault(); 
                        e.stopPropagation();
                        setSelectedProduct(prod);
                        setShowModal(true);}}
                    >
                      <strong>{prod.name}<br/>{prod.price} €</strong>
                  </Button>
              )
            }
            </div>
          )

        )
      }
      <Modal 
        show={showModal} 
        onHide={() => {
          setShowModal(false);
          setMassAmount(null);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Massapiikkaus ({selectedProduct.name})</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Kuinka monta kpl haluat piikata?
          <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Control 
              type="number"
              className="my-3"
              placeholder={"Anna lukumäärä"}
              onChange={(e) => setMassAmount(parseInt(e.target.value))}
              value={massAmount}
            />
            <div className="m-3">
              <b>Kokonaishinta:</b> {parseInt(massAmount) > 0 ? priceFormatter(massAmount * selectedProduct.price) : '-'}
            </div>
          </Form>
        </Modal.Body> 
        <Modal.Footer>
          <Button 
            variant={!(parseInt(massAmount) > 0) ? "outline-secondary" : "dark shadow-sm"}
            disabled={!(parseInt(massAmount) > 0)}
            onClick={() => handleMassSpike()}>
              Piikkaa
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default SpikeButtons;
