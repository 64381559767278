import React, {Fragment, useState} from 'react';
import {Button, Modal, Form, Row, Col} from 'react-bootstrap';
import {useTrackedState, useDispatch} from '../store';
import {addProduct} from '../reducers/productsActionCreators';
import {validatePrice} from './formatterFunctions';

const CreateNewProduct = () => {
  const {categories} = useTrackedState();
  const dispatch = useDispatch();
  
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState(null);
  const [category, setCategory] = useState(null);
  const [price, setPrice] = useState(null);
  const [errors, setErrors] = useState(
    {
      name: false, 
      category: false, 
      price: false
    }
  );

  const handleSubmit = async () => {
    try {
      setName(name.trim());
      const nameError = (name.trim() === '');
      const catError = (!category || category === 'Valitse...');
      const priceError = (!validatePrice(price));

      if (nameError || catError || priceError) {
        //console.log("there's errors")
        setErrors(
          {
            name: nameError, 
            category: catError, 
            price: priceError
          }
        );
        return;
      }

      const product = {
        name: name, 
        category_id: categories.find(c => c.category === category).category_id, 
        price: price};

      dispatch(addProduct(product));
      closeModal();
    } catch (err) {
      console.error(err.message)
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setName(null);
    setCategory(null);
    setPrice(null);
  };

  const confirmDisabled = () => {
    return (
      !(name && price && category && category !== 'Valitse...')
    );
  };

  return (
    <Fragment>
      <Button 
        variant={categories.length === 0 ? "outline-secondary" : "success shadow-sm"} 
        className="float-right"
        disabled={categories.length === 0}
        onClick={() => (setShowModal(true))}
        title={categories.length === 0 ? "Luo ensin kategoria" : null}
      >  
        <svg height="24px" viewBox="0 0 32 32" width="24px" transform="rotate(45)" >
          <path d="M20.377,16.519l6.567-6.566c0.962-0.963,0.962-2.539,0-3.502l-0.876-0.875c-0.963-0.964-2.539-0.964-3.501,0  L16,12.142L9.433,5.575c-0.962-0.963-2.538-0.963-3.501,0L5.056,6.45c-0.962,0.963-0.962,2.539,0,3.502l6.566,6.566l-6.566,6.567  c-0.962,0.963-0.962,2.538,0,3.501l0.876,0.876c0.963,0.963,2.539,0.963,3.501,0L16,20.896l6.567,6.566  c0.962,0.963,2.538,0.963,3.501,0l0.876-0.876c0.962-0.963,0.962-2.538,0-3.501L20.377,16.519z" fill="currentColor"/>
        </svg>
      </Button>
      <Modal show={showModal}  onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Uusi tuote</Modal.Title>
        </Modal.Header>
        <Form onSubmit={(e) => e.preventDefault()}>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Label>Nimi</Form.Label>
              <Form.Control 
                placeholder="" 
                value={name} 
                onChange={e => setName(e.target.value)}/>
              {!errors.name ? null : 
                <div className="text-danger sm"><i>Anna nimi</i></div>
              }
            </Col>
            <Col>
              <Form.Label>Kategoria</Form.Label>
              <Form.Control 
                as="select"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              ><Fragment>
                <option>Valitse...</option>
                {categories.map(cat => {
                  return (
                    <option key={cat.category_id}>{cat.category}</option>
                  )
                })}
                </Fragment>
            </Form.Control>
              {!errors.category ? null : 
                <div className="text-danger sm"><i>Valitse kategoria</i></div>
              }
            </Col>
            <Col>
              <Form.Label>Hinta (€)</Form.Label>
              <Form.Control 
                placeholder="Anna hinta" 
                value={price} 
                onChange={e => setPrice(e.target.value)}/>
              {!errors.price ? null : 
                <div className="text-danger sm"><i>Anna hinta positiivisena (pisteellisenä) desimaalilukuna</i></div>
              }
            </Col>
            
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={confirmDisabled() ? "outline-secondary" : "success shadow-sm"} 
            disabled={confirmDisabled()} 
            onClick={handleSubmit}
            title={confirmDisabled() ? "Täytä tiedot" : null}
          >
            Tallenna
          </Button>
        </Modal.Footer>
        </Form>
        </Modal>
      </Fragment>
  )
};

export default CreateNewProduct;