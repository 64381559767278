import {
  FETCH_HOSTS,
  ADD_HOST,
  DELETE_HOST,
  stateNames,
  SET_TO_LOADING,
  ERROR,
  apiUrl
} from './masterReducer'

const baseUrl = apiUrl + "/hosts";

export const fetchHosts = () => async (dispatch) => {
  try {
    dispatch({type:SET_TO_LOADING, setLoad: stateNames.hosts});
    const response = await fetch(baseUrl, 
      {
        method: "GET",
        headers:
          {
            "jwtToken": localStorage.jwtToken
          }
      }  
    );
    const dataJson = await response.json();
    dispatch({type:FETCH_HOSTS, hosts:dataJson});

  } catch (err) {
    dispatch({type:ERROR, message: err.message});
  }
};

export const addHost = (host) => async (dispatch) => {
  try {
    console.log("HEllO")
    dispatch({type:SET_TO_LOADING, setLoad: stateNames.hosts});
    const response = await fetch(baseUrl,
      {
        method: "POST",
        headers: 
          {
            "jwtToken": localStorage.jwtToken,
            "Content-Type" : "application/json"
          },
        body: JSON.stringify(host)
      }
    );
    const dataJson = await response.json();

    dispatch({type:ADD_HOST, host:dataJson});
  } catch (err) {
    dispatch({type:ERROR, message: err.message});
  }
}

export const deleteHost = (host_id) => async (dispatch) => {
  try {
    dispatch({type:SET_TO_LOADING, setLoad:stateNames.hosts});
    const response = await fetch(`${baseUrl}/${host_id}`,
      {
        method: "DELETE",
        headers:
            {
              "jwtToken": localStorage.jwtToken,
              "Content-Type": "applicaton/json"
            }
      }
    );
    const dataJson = await response.json()
    
    dispatch({type:DELETE_HOST, host_id: dataJson.host_id});
  } catch (err) {
    dispatch({type:ERROR, message: err.message});
  }
};
