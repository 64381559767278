import React, {Fragment, useState} from 'react';
import Select from 'react-select';
import {Spinner, Button} from 'react-bootstrap';
import SpikeButtons from './SpikeButtons';
import {useTrackedState, useDispatch} from '../store';
import {logout} from '../reducers/authActionCreators'; 
import {toast} from 'react-toastify';

const Spike = () => {
  const state = useTrackedState();
  const dispatch = useDispatch();
  const {userList, currentUser, loading} = state;
  const [selectedSpikee, setSelectedSpikee] = useState(currentUser);
  const handleChange = selection => {
    const newSpikee = selection.value;
    setSelectedSpikee(newSpikee);
    toast.dark(`Piikattavaksi vaihdettu ${newSpikee.name}`, { autoClose:2000 });
  }
  
  let options = userList.filter(u => u.name !== 'Kämppä').map(u => {
    return ({
      value: u,
      label: u.name
    })
  })

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logout());
  }

  const resetSelectedSpikee = () => {
    setSelectedSpikee(currentUser);
    toast.dark(`Piikattavaksi palautettu ${currentUser.name}`, { autoClose:2000 });
  }

  return (
      <div id="tabInnards">
      {(loading.products || loading.category_ranks) ? 
        <Spinner className="m-3 float-left" size="sm" animation="border" role="status"/> 
      :
        <Fragment>
        <table width="100%" className="mb-3">
          <tbody>
          <tr display="table">
          <td align="left">
          <Button 
            className="btn mx-3 my-3 float-left shadow-sm"
            variant="light"
            onClick={(e) => handleLogout(e)}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
              <path d="M16,17V14H9V10H16V7L21,12L16,17M14,2A2,2 0 0,1 16,4V6H14V4H5V20H14V18H16V20A2,2 0 0,1 14,22H5A2,2 0 0,1 3,20V4A2,2 0 0,1 5,2H14Z" />
            </svg>
          </Button>
          </td>
          <td>
            <h4 align="right" className="mr-3 mt-3">Piikattava:</h4>
          </td>
          <td width="220px">
            <Select 
              className="singleValue clearIndicator mt-2"
              options={options}
              value={{label:selectedSpikee.name, value:selectedSpikee}}
              defaultValue={
                {
                label: currentUser.name,
                value: userList.filter(u => u.user_id === currentUser.user_id)
                }
              }
              placeholder={selectedSpikee.name}
              onChange={handleChange}
            />
          </td>
          </tr>
          </tbody>
        </table>
        {selectedSpikee.name === 'Kämppä' ? <h3 className="my-5" align="center">Valitse piikattava</h3> : 
          <SpikeButtons spikee={selectedSpikee} resetSelectedSpikee={resetSelectedSpikee}/> 
        }
        </Fragment>
        }
        </div>
  );
};

export default Spike;