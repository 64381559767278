import React, {Fragment, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import {useDispatch, useTrackedState} from '../store';
import {resetUserPassword, checkUserPasswordToken} from '../reducers/userListActionCreators';
import Login from "./Login";
import { ERROR } from '../reducers/masterReducer';

const ResetPassword = (props) => {
  const dispatch = useDispatch();
  const {currentUser, loading} = useTrackedState();
  const [inputs, setInputs] = useState({
    password: "", 
    confirm_password: "",
  });

  const {password, confirm_password} = inputs;

  const onChange = (e) => {
    setInputs({...inputs, [e.target.name] : e.target.value});
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();
    if (confirm_password !== password) {
      dispatch({type:ERROR, message:"Salasanat eivät täsmää"});
      return;
    }
    if (password === "" || confirm_password === "") {
      dispatch({type:ERROR, message:"Tyhjä kenttä"})
      return;
    }
    try {
      //console.log(info);
      await dispatch(resetUserPassword(password, props.match.params.token));
      setTimeout(() => {
        props.history.push('/login')
      }, 1000);

    } catch (err) {
      console.error(err.message);
    }

  };

  const redirect_to_login = () => {
    if (!loading.currentUser && currentUser.user_id === "") props.history.push('/login')
  };

   useEffect( () => {
       const checkToken = async (props) => {
            const response = await dispatch(checkUserPasswordToken(props.match.params.token));
            //redirect_to_login();
        }
        checkToken(props);
   }, [])

  return (
    <Fragment>
        {currentUser.user_id === "" ? 
            <Fragment>
                {loading.currentUser ? 
                    <Fragment>
                    <h2 className="text-center my-5">Odota hetki, vahvistetaan linkkiä...</h2>
                    {/* <div align="center">
                        <Link to="/login">
                            <Button variant="outline-dark" className="my-3" >
                                Palaa kirjautumissivulle
                            </Button>
                        </Link>
                    </div> */}
                    </Fragment>
                        :
                    <Login />
                }
            </Fragment>
            :
                <Fragment>
                <h1 className="text-center my-5">Salasanan vaihto</h1>
                <p className="text-center my-5">Aseta uusi salasana käyttäjälle {currentUser.name}.</p> 
                <form onSubmit={e => onSubmitForm(e)}>
                    <input type="password" 
                    name="password" 
                    placeholder="Uusi salasana" 
                    className="form-control my-3"
                    value={password}
                    onChange={e => onChange(e)}/>

                    <input type="password"
                    name="confirm_password"
                    placeholder="Vahvista salasana"
                    className="form-control my-3"
                    value={confirm_password}
                    onChange={e => onChange(e)}/>

                    <Button 
                    variant="dark shadow-sm" 
                    type="submit" 
                    className="btn btn-dark btn-block"
                    >
                    Aseta salasana
                    </Button>
                </form>
                </Fragment> 
        }
    </Fragment>
  );
};

export default ResetPassword;