import React from 'react';
import scrollUpArrow from '../assets/scrollUpArrow.gif';
import LazyLoad from 'react-lazyload';
import Fade from 'react-reveal/Fade';
import Categories from './Categories';
import ProductsTable from './ProductsTable';
import squiggle from '../assets/squiggle1.png';
import hor_line from '../assets/hor_line.png';

const HostViewProducts = () => {
  return (
    <div id="tabInnards">
      <LazyLoad 
            height={1000} 
            placeholder={
              <div width="100%" style={{minHeight:1500}} align="center">
                <img  
                  src={scrollUpArrow} 
                  height={50} 
                  alt="Vieritä alas ladataksesi lisää sivua"
                />
              </div>
            }
          >
        <Fade duration={500}>
          <Categories />
          <table align="center">
              <tbody>
              <tr>
              <td width="45%" align="center">
                <img src={hor_line} width="100%" className="mx-1" style={{backgroundColor:"white"}} alt=""/>
              </td>
              <td>
              <img className="my-3 mx-2" src={squiggle} width={50} alt="" />
              </td>
              <td width="45%" align="center">
                <img src={hor_line} width="100%" className="mx-1" style={{backgroundColor:"white"}} alt=""/>
              </td>
              </tr>
              </tbody>
            </table>
          <ProductsTable />
        </Fade>
      </LazyLoad>
    </div>
  );
};

export default HostViewProducts;