import React, {Fragment, useState} from 'react';
import Fade from 'react-reveal/Fade';
import {useTrackedState, useDispatch} from '../store';
import {switchCategoryRanks, renameCategory, addCategory, deleteCategory} from '../reducers/categoriesActionCreators';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Button, Spinner, Modal, Form} from 'react-bootstrap';
import {nameFormatter} from './formatterFunctions';

const Categories = () => {
  const {categories, products, loading} = useTrackedState();
  const dispatch = useDispatch();

  const [showNew, setShowNew] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [newCategory, setNewCategory] = useState('');
  const [categoryToDelete, setCategoryToDelete] = useState({category:'', category_id:0});

  const handleRankUp = (row, categories) => {
    //console.log("move up");
    const categoriesToSwitch = categories.filter(cr => 
      cr.rank === row.rank || cr.rank === row.rank-1);
    dispatch(switchCategoryRanks(categoriesToSwitch));
  };

  const handleRankDown = (row, categories) => {
    //console.log("move down");
    const categoriesToSwitch = categories.filter(cr => 
      cr.rank === row.rank || cr.rank === row.rank+1);
    dispatch(switchCategoryRanks(categoriesToSwitch));
  };

  const handleNewCategory = () => {
    dispatch(addCategory({category: newCategory, rank: categories.length+1}));
    setShowNew(false);
  };

  const handleShowDelete = (category) => {
    setCategoryToDelete(category);
    setShowDelete(true);
  };

  const handleDeleteCategory = () => {
    dispatch(deleteCategory(categoryToDelete.category_id))
    setShowDelete(false);
  };

  const deleteDisabled = (row) => {
    return (products.filter(p => p.category_id === row.category_id).length > 0)
  };

  const columns = [
    {
      dataField: 'dummyUp',
      isDummyField: true,
      editable: false,
      text:"Järjestys",
      headerStyle: {width: '70px'},
      formatExtraData: {handleRankUp, categories:categories},
      formatter: (cell, row, rowIndex, {handleRankUp, categories}) => {
        return (
          <Button
            disabled={row.rank === 1} 
            hidden={row.rank === 1} 
            className='m-1 float-left shadow-sm'
            variant='dark'
            onClick={() => handleRankUp(row, categories)}>
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-up" fill="white" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
            </svg>
          </Button>
        )
      }
    }, {
      dataField: 'dummyDown',
      isDummyField: true,
      editable: false,
      headerStyle: {width: '70px'},
      formatExtraData: {handleRankDown, categories:categories},
      formatter: (cell, row, rowIndex, {handleRankDown, categories}) => {
        return (
          <Button 
            disabled={row.rank === categories.length}
            hidden={row.rank === categories.length}
            className='m-1 float-left shadow-sm'
            variant='dark'
            onClick={() => handleRankDown(row, categories)}>
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-down" fill="white" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
            </svg>
          </Button>
        )
      }
    }, {
      dataField: 'rank',
      editable: false,
      sort:true,
      hidden: true,
    }, {
      dataField: 'category',
      text: 'Nimi',
      editable: true,
      style: {overflowWrap:'break-word'},
      formatter: (cell, row, rowIndex, extradata) => {
        return (
          <Fragment>
            <div className="my-3">
            <Fade>
            {nameFormatter(cell)}
            </Fade>
            </div>
            </Fragment>);
      },
      validator: (newValue, row) => {
        if (categories.filter(c => c.category_id !== row.category_id).map(c => c.category).includes(newValue)) {
          return {
            valid: false,
            message: 'Kategorioiden on oltava erinimisiä.'
          };
        }
        return true;
      }
    }, {
      text: 'Tuotteita',
      datafield: 'dummyCount',
      isDummyField: true,
      editable: false,
      style: {overflowWrap:'break-word'},
      formatExtraData: {products},
      headerAlign: "right",
      formatter: (cell, row, rowIndex, {products}) => {
        return (
          <Fragment>
            <div className="my-3" align="right">
            {products.filter(p => p.category_id === row.category_id).length}
            </div>
          </Fragment>);
      },
    }, {
      text: '',
      isDummyField: true,
      dataField: "",
      editable: false,
      formatExtraData: {handleShowDelete, products},
      formatter: (cell, row, rowIndex, {handleShowDelete, products}) => {
        return (
          <Fragment>
            <Button
              onClick={() => handleShowDelete(row)} 
              disabled={deleteDisabled(row)}
              variant={deleteDisabled(row) ? "outline-secondary" : "danger shadow-sm"} 
              className="float-right"
              title={deleteDisabled(row) ? "Kategorian tulee olla tyhjä ennen poistamista": null}
            >
              <svg height="24px" viewBox="0 0 32 32" width="24px" >
                <path d="M20.377,16.519l6.567-6.566c0.962-0.963,0.962-2.539,0-3.502l-0.876-0.875c-0.963-0.964-2.539-0.964-3.501,0  L16,12.142L9.433,5.575c-0.962-0.963-2.538-0.963-3.501,0L5.056,6.45c-0.962,0.963-0.962,2.539,0,3.502l6.566,6.566l-6.566,6.567  c-0.962,0.963-0.962,2.538,0,3.501l0.876,0.876c0.963,0.963,2.539,0.963,3.501,0L16,20.896l6.567,6.566  c0.962,0.963,2.538,0.963,3.501,0l0.876-0.876c0.962-0.963,0.962-2.538,0-3.501L20.377,16.519z" fill="currentColor"/>
              </svg>
            </Button>
          </Fragment>
        );
      },
      headerFormatter: () => {
        return (<Button 
        variant="success shadow-sm" 
        className="float-right"
        disabled={false}
        onClick={() => setShowNew(true)}
      >
        <svg height="24px" viewBox="0 0 32 32" width="24px" transform="rotate(45)" >
          <path d="M20.377,16.519l6.567-6.566c0.962-0.963,0.962-2.539,0-3.502l-0.876-0.875c-0.963-0.964-2.539-0.964-3.501,0  L16,12.142L9.433,5.575c-0.962-0.963-2.538-0.963-3.501,0L5.056,6.45c-0.962,0.963-0.962,2.539,0,3.502l6.566,6.566l-6.566,6.567  c-0.962,0.963-0.962,2.538,0,3.501l0.876,0.876c0.963,0.963,2.539,0.963,3.501,0L16,20.896l6.567,6.566  c0.962,0.963,2.538,0.963,3.501,0l0.876-0.876c0.962-0.963,0.962-2.538,0-3.501L20.377,16.519z" fill="currentColor"/>
        </svg>
      </Button>)
      }
    }
  ];

  const cellEdit = cellEditFactory({
    mode: 'click',
    autoSelectText: true,
    blurToSave: true,
    afterSaveCell: (oldValue, newValue, row, column) => {
      //console.log(row);
      if (oldValue !== newValue)
        dispatch(renameCategory(row.category_id, newValue));
    }
  });

  const defaultSorted = [{
    dataField: 'rank',
    order: 'asc'
  }];

  return ( 
    <Fragment>
      <h2 className='pt-4'>Kategoriat
      {loading.categories ? 
        <Spinner className="m-3 float-right" size="sm" animation="border" role="status"/>
       : null} </h2>
      <BootstrapTable 
        keyField='rank'
        cellEdit={ cellEdit }
        data={categories}
        columns={columns}
        defaultSorted={defaultSorted}
        bordered={false}
        condensed
      />
      <Modal show={showNew} onHide={() => setShowNew(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Uusi kategoria</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Control 
              type="text"
              name="newCat"
              value={newCategory}
              className="my-3"
              isInvalid={categories.filter(c => c.category === newCategory).length > 0}
              onChange={(e) => setNewCategory(e.target.value)}
              placeholder={"Anna kategorian nimi"}
            />
          </Form>
          <Modal.Footer>
          <Button 
            variant={categories.filter(c => c.category === newCategory).length > 0 || newCategory === "" ? 
              "outline-secondary" : "success shadow-sm"}
            disabled={categories.filter(c => c.category === newCategory).length > 0 || newCategory === "" }
            onClick={() => handleNewCategory()}
          >
            Tallenna
          </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
      <Modal show={showDelete} onHide={() => setShowDelete(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Poista Kategoria</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        Haluatko varmasti poistaa kategorian: "{categoryToDelete.category}" ?
        </Modal.Body>
        <Modal.Footer> 
          <Button 
            variant="danger shadow-sm"
            onClick={() => handleDeleteCategory()}>
              Poista
            </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
}

export default Categories;