import React from 'react';

// helper functions used for formatting various data inside tables

export function round (val) {
  return (Math.round(val * 100) / 100).toFixed(2)
}

export function nameFormatter (cell, row) {
  return (<strong>{cell}</strong>);
}

export function timestampFormatter (cell, row) {
  const date = new Date(cell);
  return date.toLocaleString("fi-FI");
}

export function moneyFormatter(cell, row) {
  return (
    <span>
      <strong style={ { color: cell < 0 ? '#ea7125' : '#5abbc0'} } > {(cell >= 0) ? '+' : ''}{round(cell)} € </strong>
    </span>
  )
};

export function priceFormatter(cell, row) {
  return (
    <span>
      <strong>{round(cell)} € </strong>
    </span>
  )
}

export function validatePrice (val) {
  const exp = /^[0-9]+\.?[0-9]*$/;
  return val.match(exp);
}