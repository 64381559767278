import React, {Fragment, useState, useEffect} from 'react';
import {Form, Spinner} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, {Type} from 'react-bootstrap-table2-editor';
import {priceFormatter, nameFormatter, validatePrice} from './formatterFunctions';
import {toast} from 'react-toastify';
import CreateNewProduct from './CreateNewProduct';
import DeleteProduct from './DeleteProduct';
import {useTrackedState, useDispatch } from '../store';
import {updateProduct} from '../reducers/productsActionCreators';


toast.configure();

const ProductsTable = () => {
    const {products, categories, loading} = useTrackedState();
    const dispatch = useDispatch();
  
    const [prodsWithCategories, setProdsWithCategories] = useState([]);
  
    const columns = [
      {
        dataField: 'name',
        text: 'Nimi',
        sort: true,
        editable: true,
        headerStyle: {width: '28%'},
        style: {overflowWrap:'break-word'},
        formatter: (cell, row, rowIndex, extradata) => {
          return (
            <Fragment>
              <div 
                className="my-3" 
                align="left"
                style={{opacity: row.hidden ? "35%" : "100%"}}>
              {nameFormatter(cell)}
              </div>
  
              </Fragment>);
        },
        headerAlign:'left',
        validator: (newValue, row, column) => {
          if (newValue === "") {
            return {
              valid: false,
              message: 'Nimi ei voi olla tyhjä.'
            };
          }
          else if (products.filter(p => p.product_id !== row.product_id)
            .map(p => p.name).includes(newValue)) {
            return {
              valid: false,
              message: 'Tuotteiden on oltava erinimisiä'
            }
          }
          return true;
        }
      }, {
        dataField: 'category_id',
        text: 'Kategoria',
        sort: true, 
        sortValue: (cell, row) => row.category,
        editable: true,
        headerAlign:'left',
        editor: {
          type: Type.SELECT,
          options: 
            categories
            .map(cat => {
              return (
                {
                  value: cat.category_id, 
                  label: cat.category
                }
              );
            })
        },
        headerStyle: {width: '25%'},
        style: {overflowWrap:'break-word'},
        formatter: (cell, row, rowIndex, extraData) => {
          return (
            <Fragment>
              <div 
                className="my-3" 
                align="left"
                style={{opacity: row.hidden ? "35%" : "100%"}}>
                {row.category}
              </div>
              </Fragment>);
        },
      }, {
        dataField: 'price',
        text: 'Hinta',
        headerStyle: {width: '17%'},
        headerAlign:'right',
        style: {overflowWrap:'break-word'},
        sort: true,
        formatter: (cell, row, rowIndex, extradata) => {
          return (
            <Fragment>
              <div 
                className="my-3" 
                align="right"
                style={{opacity: row.hidden ? "35%" : "100%"}}>
              {priceFormatter(cell)}
              </div>
              </Fragment>);
        },
        sortFunc: (a, b, order) => {  
          if (order === "asc") return a - b; 
          return b-a; },
        validator: (newValue) => {
          if (!validatePrice(newValue)) {
            return {
              valid: false,
              message: 'Hinnan on oltava positiivinen desimaaliluku.'
            };
          }
          return true;
        }
      }, {
        text: "Näkyvä",
        dataField: 'hidden',
        editable: false,
        sort:true,
        headerStyle: {width: '13%'},
        headerAlign:'center',
        formatter : (cell, row, rowIndex, extradata) => {
          return (
            <div align="center">
              <Form.Switch
                id={row.product_id}
                label=""
                className = "m-3"
                type="checkbox"
                checked={!row.hidden}
                onChange={() => dispatch(updateProduct({...row, hidden: !(row.hidden)}))}
              />
            </div>
          )
        }
      }, {
        text: '',
        isDummyField: true,
        dataField: "",
        editable: false,
        headerAlign:'center',
        formatter: (cell, row, rowIndex) => {
          return (
            <div className="my-2" align="center">
              <DeleteProduct prod={row}/>
            </div>
          )
        },
        headerFormatter: () => {
          return (
            <div className="my-2" align="center">
              <CreateNewProduct />
            </div>
          )
        }
      }
    ];
  
    const defaultSorted = [{
      dataField: 'category_id',
      order: 'asc'
    }];
  
    const cellEdit = cellEditFactory({
      mode: 'click',
      blurToSave: true,
      autoSelectText: true,
      afterSaveCell: (oldValue, newValue, row, column) => {
        if (oldValue !== newValue)  {
          const updatedProduct = {...row, category_id: parseInt(row.category_id)};
          //console.log(updatedProduct);
          dispatch(updateProduct(updatedProduct));
        }
      }
    });
  
    useEffect(() => {
      setProdsWithCategories(products.map(p => {
        return (
          {
            ...p,
            category: 
              categories.filter(c => c.category_id === p.category_id)
              .map(c => c.category)[0]
          }
        )
      }))
    }, [products, categories])
  
    return (
        <Fragment>
            <h2 className='pt-4'>Tuotteet
                {loading.products ? 
                    <Spinner className="m-3 float-right" size="sm" animation="border" role="status"/>
                : null} 
            </h2>
                <BootstrapTable 
                    keyField='product_id'
                    data={prodsWithCategories}
                    columns={columns}  
                    defaultSorted={defaultSorted}
                    cellEdit={ cellEdit }
                    bordered={false}
                    className="mt-3"
                    hover
                    condensed
                    bootstrap4
            />
        </Fragment>
    );
};

export default ProductsTable;