import { toast } from 'react-toastify';

import {
  FETCH_USERLIST,
  DELETE_USER,
  UPDATE_USER_EMAIL,
  UPDATE_USER_PASSWORD,
  stateNames,
  SET_TO_LOADING,
  ERROR,
  VALIDATE_USER_TOKEN,
  apiUrl
} from './masterReducer'

import {sendMail} from './settingsActionCreators';

const baseUrl = apiUrl + "/users";

export const fetchUserList = () => async (dispatch) => {
  try {
    dispatch({type:SET_TO_LOADING, setLoad: stateNames.userList});
    const response = await fetch(`${baseUrl}/all`, {
      headers: {
        "jwtToken" : localStorage.jwtToken
      }
    });
    const dataJson = await response.json();
    dispatch({type:FETCH_USERLIST, userList: dataJson});
  } catch (err) {
    dispatch({type:ERROR, message: err.message});
  }
};

export const fetchUserNames = () => async (dispatch) => {
  try {
    dispatch({type:SET_TO_LOADING, setLoad: stateNames.userList});
    const response = await fetch(`${baseUrl}/names`, {
      headers: {
        "jwtToken" : localStorage.jwtToken
      }
    });
    const dataJson = await response.json();
    dispatch({type:FETCH_USERLIST, userList: dataJson});
  } catch (err) {
    dispatch({type:ERROR, message: err.message});
  }
}

export const updateUserEmail = (user_id, password, old_email, new_email) => async(dispatch) => {
  try {
    dispatch({type:SET_TO_LOADING, setLoad:stateNames.userList});
    const body = {password:password, new_email:new_email};
    const response = await fetch(`${baseUrl}/set_data/email/${user_id}`,
      {
        method: 'PUT',
        headers:
          {
            "jwtToken": localStorage.jwtToken,
            "Content-Type": "application/json"
          },
        body: JSON.stringify(body)
      }
    )
    console.log(response);
    if (response.status === 401) {
      dispatch({type:ERROR, message:"Kyseinen sähköpostiosoite on jo käytössä."});
      return;
    }

    else if (response.status === 403) {
      dispatch({type:ERROR, message:"Väärä salasana"});
      return;
    }
    else if (response.state === 404) {
      dispatch({type:ERROR, message:"Serveriä ei löytynyt"});
      return;
    }
    const dataJson = await response.json();

    dispatch({type:UPDATE_USER_EMAIL, user_id:dataJson.user_id, email:dataJson.email});
    toast.success(`Sähköpostiosoite vaihdettu.`);

    dispatch({type:SET_TO_LOADING, setLoad:stateNames.settings});
    const confirmationMailContent = 
    `Sähköpostiosoitteesi on vaihdettu ePiikki-järjestelmässä \n
    osoitteesta: ${old_email} 
    osoitteeseen: ${new_email}. 
    
    
    Jos et itse tehnyt tätä muutosta, ota yhteys Isäntään.`;
    const confirmationMail = [
      {
        recipient:new_email,
        content:confirmationMailContent
      },
      {
        recipient:old_email,
        content:confirmationMailContent
      }
    ]
    dispatch(sendMail('Uusi sähköpostiosoite', confirmationMail));

  } catch (err) {
    console.error(err.message);
    dispatch({type:ERROR, message:err.message});
  }
};

export const updateUserPassword = (user_id, old_password, new_password) => async(dispatch) => {
  try {
    dispatch({type:SET_TO_LOADING, setLoad: stateNames.userList});
    const body = {old_password:old_password, new_password:new_password};
    const response = await fetch(`${baseUrl}/set_data/password/${user_id}`,
      {
        method: "PUT",
        headers: {
          "jwtToken" : localStorage.jwtToken,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      }
    );

    if (response.status === 403) {
      dispatch({type:ERROR, message:"Väärä salasana"});
      return;
    }
    dispatch({type:UPDATE_USER_PASSWORD})
    toast.success("Salasanasi on vaihdettu.");
  } catch (err) {
    dispatch({type:ERROR, message: err.message});
  }
};

export const checkUserPasswordToken = (token) => async(dispatch) => {
  try {
    dispatch({type:SET_TO_LOADING, setLoad: stateNames.currentUser})
    const response = await fetch(`${baseUrl}/check_password_token/${token}`,
      {
        headers: {
          "jwtToken" : localStorage.jwtToken
        }
      }
    );
    if (response.status === 403) {
      dispatch({type: ERROR, message: "Salasanan resetointilinkki on väärä tai vanhentunut."})
      return false;
    }
    if (response.status === 301) {
      dispatch({type: ERROR, message: "Salasanan resetointilinkki on vanhentunut."})
      return false;
    } 
    const dataJson = await response.json();
    console.log("setting current user: ")
    console.log(dataJson);
    dispatch({type:VALIDATE_USER_TOKEN, name: dataJson.name, user_id: dataJson.user_id});
    return true;
    
  } catch (err) {
    dispatch({type:ERROR, message: err.message});
  }
};

export const resetUserPassword = (new_password, token) => async(dispatch) => {
  try {
    dispatch({type:SET_TO_LOADING, setLoad: stateNames.userList});
    console.log("token: " + token);
    console.log("new_password: " + new_password );
    const body = {new_password:new_password};
    const response = await fetch(`${baseUrl}/set_data/reset_password/${token}`,
      {
        method: "PUT",
        headers: {
          "jwtToken" : localStorage.jwtToken,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      }
    );

    if (response.status === 403) {
      dispatch({type:ERROR, message:"Väärä salasana"});
      return;
    }
    toast.success("Salasanasi on vaihdettu. Voit nyt kirjautua sisään.");
  } catch (err) {
    dispatch({type:ERROR, message: err.message});
  }
};

export const deleteUser = (user_id, email) => async (dispatch) => {
  try {
    dispatch({type:SET_TO_LOADING, setLoad:stateNames.userList});
    await fetch(`${baseUrl}/${user_id}`,
      {
        method: "DELETE",
        headers: 
          {
            "jwtToken": localStorage.jwtToken
          }
      }
    )
    const confirmationMailContent = 
      `Käyttäjätunnuksesi on poistettu ePiikki-järjestelmästä.
      
      Terveisin, Isäntä.`;
    const confirmationMail = [
      {
        recipient:email,
        content:confirmationMailContent
      },
    ];
    dispatch(sendMail('Käyttäjätunnus poistettu', confirmationMail));
    dispatch({type:DELETE_USER, user_id:user_id});
    toast.success("Käyttäjä poistettu");
  } catch (err) {
    console.log(err.message);
    dispatch({type:ERROR, message: err.message});
  }
}