import React from 'react';
import {Form} from 'react-bootstrap';
import {useTrackedState, useDispatch} from '../store';
import {addHost, deleteHost} from '../reducers/hostsActionCreators';
import {toast} from 'react-toastify';

const HostStatusSwitch = ({user}) => {
  const {hosts, currentUser} = useTrackedState();
  const dispatch = useDispatch();
  const userIsHost = hosts.map(h => h.host_id).includes(user.user_id);

  const handleSwitch = (e) => {
    e.preventDefault();
    userIsHost ? handleDeleteHost() : handleAddHost();
  };

  const handleDeleteHost = () => {
    try {
      if (hosts.length < 2) {
        toast.error("Isäntiä on oltava vähintään 1");
        return
      }
      if (user.user_id === currentUser.user_id) {
        toast.error("Et voi poistaa itseäsi isännistöstä");
        return
      }
      dispatch(deleteHost(user.user_id));
      toast.success(`${user.name} poistettu isännistöstä`);
    } catch (err) {
      console.error(err.message);
    }
  }

  const handleAddHost = () => {
    try {
      const newHost = {
        host_id : user.user_id
      };
      console.log(newHost);
      dispatch(addHost(newHost));
      toast.success(`Lisätty ${user.name} isännäksi`);
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <div align="center" className="my-2">
      Isäntä <br/>
        <Form.Switch
          id={`hostSwitch${user.name}`}
          checked={userIsHost}
          onChange={(e) => handleSwitch(e)}
        />
    </div>
  )
};

export default HostStatusSwitch;