import {toast} from 'react-toastify';
import React from 'react';
import {Button} from 'react-bootstrap';

import {
  FETCH_SPIKES,
  ADD_SPIKE,
  CLEAR_SPIKES,
  CHANGE_CREDIT,
  CHARGE_MULTIPLE_USERS,
  stateNames,
  SET_TO_LOADING,
  ERROR,
  apiUrl
} from './masterReducer'

const baseUrl = apiUrl + '/spikes';

export const fetchSpikes = () => async (dispatch) => {
  try {
    dispatch({type:SET_TO_LOADING, setLoad: stateNames.spikes});
    const response =  await fetch(`${baseUrl}/all_spikes`, {
      headers: {
        "jwtToken" : localStorage.jwtToken
      }
    });
    const dataJson = await response.json();
    const dataWithDates = dataJson.map(s => ({...s, time: new Date(s.time)}))
    dispatch({type:FETCH_SPIKES, spikes:dataWithDates});

  } catch (err) {
    dispatch({type:ERROR, message: err.message});
  }
};

export const fetchUserSpikes = (user_id) => async (dispatch) => {
  try {
    dispatch({type:SET_TO_LOADING, setLoad: stateNames.spikes});
    const response =  await fetch(`${baseUrl}/user_spikes/${user_id}`, {
      headers: {
        "jwtToken" : localStorage.jwtToken
      }
    });
    const dataJson = await response.json();
    const dataWithDates = dataJson.map(s => ({...s, time: new Date(s.time)}))
    dispatch({type:FETCH_SPIKES, spikes:dataWithDates});
  } catch (err) {
    dispatch({type:ERROR, message: err.message});
  }
};

export const addSpike = (spike) => async (dispatch) => {
  try {
    dispatch({type:SET_TO_LOADING, setLoad:stateNames.spikes});
    dispatch({type:SET_TO_LOADING, setLoad:stateNames.userList});
    const response = await fetch(baseUrl,
      {
        method: 'POST',
        headers: {
          "jwtToken": localStorage.jwtToken,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(spike)
      }
    );
    const dataJson = await response.json();
    const spikeWithDate = {...dataJson, time: new Date(dataJson.time)};
    //console.log(spikeWithDate);
    dispatch({type:CHANGE_CREDIT, spikee_id:dataJson.spikee_id, new_credit:dataJson.credit_after_spike});
    dispatch({type:ADD_SPIKE, spikes:spikeWithDate});
    
    toast.dark(

      <table width="100%">
        <td className="float-left my-3">
          {spike.product_name} piikattu!
        </td>
        <td>
          <Button 
            className="float-right my-2 mx-2" 
            variant='outline-light' 
            onClick={(e) => {
              e.target.disabled = true /*disable button after first press*/
              dispatch(undoSpike(spike))}
            }
          >
             <svg height="24" viewBox="0 0 512 512" width="24" fill="currentColor">
               <path d="M447.9,368.2c0-16.8,3.6-83.1-48.7-135.7c-35.2-35.4-80.3-53.4-143.3-56.2V96L64,224l192,128v-79.8   c40,1.1,62.4,9.1,86.7,20c30.9,13.8,55.3,44,75.8,76.6l19.2,31.2H448C448,389.9,447.9,377.1,447.9,368.2z"/>
             </svg>
          </Button>
        </td>
      </table>
    );
    
  } catch (err) {
    dispatch({type:ERROR, message: err.message});
  }
 };

export const undoSpike = (spike) => async (dispatch) => {
  try {
    dispatch({type:SET_TO_LOADING, setLoad:stateNames.spikes});
    dispatch({type:SET_TO_LOADING, setLoad:stateNames.userList});
    const undoneSpike = {...spike, 
      product_name: `Peruutus (${spike.product_name})`, 
      product_id: spike.product_id * -1, 
      price: spike.price*-1};
    
    const response = await fetch(baseUrl,
      {
        method: 'POST',
        headers: {
          "jwtToken": localStorage.jwtToken,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(undoneSpike)
      }
    );

    const dataJson = await response.json();
    const spikeWithDate = {...dataJson, time: new Date(dataJson.time)};
    dispatch({type:CHANGE_CREDIT, spikee_id:dataJson.spikee_id, new_credit:dataJson.credit_after_spike});
    dispatch({type:ADD_SPIKE, spikes:spikeWithDate});
    toast.dark(`${spike.product_name} peruttu!`);
  } catch (err) {
    dispatch({type:ERROR, message: err.message});
  }
};

export const addCharge = (chargeData) => async (dispatch) => {
  try {
    dispatch({type:SET_TO_LOADING, setLoad:stateNames.userList});
    dispatch({type:SET_TO_LOADING, setLoad:stateNames.spikes});

    const response = await fetch(`${baseUrl}/charge`,
      {
        method: "POST",
        headers:
          {
            "jwtToken": localStorage.jwtToken,
            "Content-Type": "application/json"
          },
        body: JSON.stringify(chargeData)
      }
    );
    const {chargedSpikees, addedSpikes} = await response.json();
    const spikesWithDate = addedSpikes.map(s => {return {...s, time: new Date(s.time)}});
    dispatch({type:CHARGE_MULTIPLE_USERS, users:chargedSpikees});
    dispatch({type:ADD_SPIKE, spikes:spikesWithDate});
    toast.success(`Saldo(t) muutettu!`);
  } catch (err) {
    dispatch({type:ERROR, message: err.message});
  }
} 

export const clearSpikes = (spikee_ids) => async (dispatch) => {
  try {
    dispatch({type:SET_TO_LOADING, setLoad: stateNames.spikes});
    const response = await fetch(`${baseUrl}/clear_spikes`,
      {
        method: 'DELETE',
        headers:
          {
            "jwtToken": localStorage.jwtToken,
            "Content-Type": "application/json"
          },
        body: JSON.stringify(spikee_ids)
      }
    );
    if (response.status === 204){
      toast.error('Saldo ei saa olla negatiivinen');
      return;
    }
    const dataJson = await response.json();
    if (response.status !== 200) {
      dispatch({type:ERROR, message: dataJson});
      return;
    }

    dispatch({type:CLEAR_SPIKES, spikee_ids:spikee_ids});
    toast.success(`Piikit pyyhitty`);

  } catch (err) {
    dispatch({type:ERROR, message: err.message});
  }
};