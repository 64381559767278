import React, {Fragment, useState} from 'react';
import { Link } from 'react-router-dom';
import {Button} from 'react-bootstrap';
import { toast } from 'react-toastify';
import {send_password_reset} from '../reducers/authActionCreators';
import {useDispatch} from '../store';

toast.configure();

const ForgotPassword = (props) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("")

  const onChange = (e) => {
    setEmail(e.target.value);
  }

  const onSubmitForm = (e) => {
    e.preventDefault();
    try {
      dispatch(send_password_reset(email));
      props.history.push("/login")
    } catch (err) {
      console.error(err.message);
    }
  }

  return (
    <Fragment>
      <h1 className="text-center my-5">Salasanan resetointi</h1>
      <p className="text-center my-5">Anna sähköpostiosoite, jolla olet rekisteröitynyt ePiikkiin. Sinulle lähetetään linkki, jota kautta voit asettaa itsellesi uuden salasanan.</p>
      <form onSubmit={e => onSubmitForm(e)}>
          <input 
            type="email"
            name="email"
            placeholder="Sähköpostiosoite"
            className="form-control my-3"
            value={email}
            onChange={(e) => onChange(e)}
          />
          <Button 
            variant="dark shadow-sm"
            type="submit" 
            className="my-3 btn btn-dark btn-block"
          >
            Lähetä sähköposti
          </Button>
      </form>
      <Link to="/login">
        <Button 
          variant="outline-dark">
            Takaisin
        </Button>
      </Link>
    </Fragment>
  );
};

export default ForgotPassword;