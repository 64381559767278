import React, {Fragment, useState, useEffect} from 'react';
//import LazyLoad from 'react-lazyload';
import {Tabs, Tab, Spinner} from 'react-bootstrap';
import Spike from './Spike'
import Profile from './Profile';
import HostView from './HostView';
import {useDispatch, useTrackedState} from '../store';
import {fetchSpikes, fetchUserSpikes} from '../reducers/spikesActionCreators';
import {fetchUserList, fetchUserNames} from '../reducers/userListActionCreators';
import {fetchProducts} from '../reducers/productsActionCreators'; 
import {fetchCategories} from '../reducers/categoriesActionCreators';
import {fetchHosts} from '../reducers/hostsActionCreators';
import {fetchSettings} from '../reducers/settingsActionCreators';
import titleLogo from '../assets/titleLogo.png';
//import rest from '../assets/rest.png';
//import rest_flipped from '../assets/rest_flipped.png';


const Dashboard = () => {
  const {currentUser, spikes, hosts, loading} = useTrackedState()
  const dispatch = useDispatch();
  const [fetchedHostFlag, setFetchedHostFlag] = useState(false);

  //console.log("RENDERING DASHBOARD")

  useEffect(() => {
    dispatch(fetchUserNames());
    dispatch(fetchCategories());
    dispatch(fetchProducts());
  }, [dispatch]);

  const onTabChange = (key) => {
    if (key === "profile") {
      if (spikes.length === 0)
        dispatch(fetchUserSpikes(currentUser.user_id));
    } else if (key === "hostview" && !fetchedHostFlag) {
      setFetchedHostFlag(true);
      dispatch(fetchUserList());
      dispatch(fetchSpikes());
      dispatch(fetchHosts());
      dispatch(fetchSettings());
    }
  };

  return (
    <Fragment>
      {/*<div style={{backgroundColor:"whitesmoke", height:"30px"}} className="d-block">
      /* (loading.hosts || 
        loading.products || 
        loading.categories ||
        loading.spikes || 
        loading.userList || 
        loading.currentUser) ? 
      <h2><Spinner 
          size="sm" 
          animation="border" 
          className="float-right"
      role="status"/></h2> : null
      </div>*/}
    <div style={{backgroundColor:"whitesmoke"}}>
    <img 
      src={titleLogo} 
      style={{maxHeight:65}}  
      className=" ml-2 mt-2 mb-1 justify-content-start" alt="ePiikki logo"/>
    <Tabs 
      defaultActiveKey="spike"
      style={{backgroundColor:"whitesmoke", marginTop:"35px"}}
      className="flex-end justify-content-end float-right"
      //style={{marginTop:"55px"}}
      onSelect={onTabChange}>
      <Tab id="dashboardTab" eventKey="spike" title="Piikki" >
          <Spike/>
      </Tab>
      {currentUser.name === 'Kämppä' ? null :
        <Tab 
          id="dashboardTab"
          eventKey="profile" 
          title="Omat tiedot">
              <Profile />
        </Tab>
      }
      {!currentUser.isAdmin ? null :
        <Tab 
          id="hostUpperTab"
          eventKey="hostview" 
          title="Isäntä"
        >
            <HostView />
        </Tab>
      }
    </Tabs>
    </div>
  </Fragment>
  );
}

export default Dashboard;
