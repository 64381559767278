import React, {Fragment, useState, useEffect} from 'react';
import Fade from 'react-reveal/Fade';
import {useTrackedState, useDispatch} from '../store';
import {Form, Row, Col, Button, Spinner} from 'react-bootstrap';
import {updateSettings} from '../reducers/settingsActionCreators';

const HostSettings = () => {
  const {settings, loading} = useTrackedState();
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState(settings);

  const handleUpdateSettings = (e) => {
    e.preventDefault();
    try {
      dispatch(updateSettings(inputs))
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    setInputs(settings);
  }, [settings]);

  return (
    <div id="tabInnards">
      {loading.settings ? 
        <Spinner className="m-3 " size="sm" animation="border" role="status"/>
      :
        <Fragment>
          <Fade duration={500}>
            <Form>
              <Form.Row width="100%">
                  <Form.Label className="my-3"><strong>Maksukehotteen otsikko:</strong></Form.Label> 
                  <Form.Control 
                    type="text" 
                    className="form-control-lg" 
                    value={inputs.title}
                    onChange={(e) => setInputs({...inputs, title: e.target.value})}
                    placeholder="Otsikko"/>
              </Form.Row>
              <Row>
                <Col>
                  <Form.Label className="my-3"><strong>Saateviesti:</strong></Form.Label> 
                  <Form.Control 
                    as="textarea" 
                    value={inputs.message}
                    onChange={(e) => setInputs({...inputs, message: e.target.value})}
                    rows="5" 
                    placeholder="Viesti, joka lähetetään maksukehotteen yhteydessä."
                  />
                </Col>
              </Row>    
              <Row> 
                <Col>
                  <Form.Label className="my-3"><strong>Vastaanottaja:</strong></Form.Label>
                </Col>
                <Col>
                  <Form.Control 
                    type="text" 
                    className="form-control-lg my-3" 
                    value={inputs.payee}
                    onChange={(e) => setInputs({...inputs, payee: e.target.value})}
                    placeholder="Tasavallan Isäntä"/>
                </Col>
              </Row>
              <Row>
                <Col> 
                <Form.Label className="my-3"><strong>Tilinumero:</strong></Form.Label>
              </Col>
                <Col>
                  <Form.Control 
                    type="text" 
                    className="form-control-lg my-3" 
                    value={inputs.bank_account}
                    onChange={(e) => setInputs({...inputs, bank_account: e.target.value})}
                    placeholder="IBAN"/>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label className="my-3"><strong>Viitenumero / Maksuviesti:</strong></Form.Label>
                </Col>
                <Col>
                  <Form.Control 
                    type="text" 
                    className="form-control-lg my-3" 
                    placeholder="Viitenro"
                    value={inputs.reference}
                    onChange={(e) => setInputs({...inputs, reference: e.target.value})}
                  />
                </Col>
              </Row>
              {true ? null : /*This functionality not implemented in beta version yet*/ 
              <Row>
                <Col>
                  <Form.Label className="my-3"><strong>Eräpäivä (päivien määrä laskun lähetyksestä):</strong></Form.Label>
                </Col>
                <Col>
                <Form.Control type="number" className="form-control-lg my-3" placeholder="7"/>
                </Col>
              </Row> }
            </Form>
            <Button 
              disabled={inputs === settings}
              onClick={(e) => handleUpdateSettings(e)}
              variant={inputs === settings ? 'outline-secondary' : "success"}
              title={inputs === settings ? "Maksukehotusta ei ole muutettu" : "Tallenna muutokset"}
              className='m-3 float-right'
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                <path d="M15.003 3h2.997v5h-2.997v-5zm8.997 1v20h-24v-24h20l4 4zm-19 5h14v-7h-14v7zm16 4h-18v9h18v-9z"/>
              </svg>
            </Button>
          </Fade>
        </Fragment>
      }
    </div>
  );
};

export default HostSettings;