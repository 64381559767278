import React, {Fragment, useState} from 'react';
import {useDispatch} from '../store';
import {clearSpikes} from '../reducers/spikesActionCreators';
import {Button, Modal} from 'react-bootstrap';

const ClearSpikesButton = ({spikees}) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const handleClearSpikes = () => {
    setShowModal(false);
    const spikeeIds = spikees.map(s => s.user_id);
    dispatch(clearSpikes(spikeeIds));
  };

  const isDisabled = () => {
    return (
      spikees.length === 0 || 
      spikees.filter(s => s.credit < 0).length > 0
    );
  }

  return (
    <Fragment>
      <Button
          disabled={isDisabled()}
          variant={isDisabled() ? "outline-secondary" : 'dark shadow-sm'} 
          className="m-3"
          title={isDisabled() ? "Ennen piikkihistorian pyyhkimistä käyttäjän saldo on oltava vähintään 0€" : null} 
          onClick={() => setShowModal(true)}>
            <svg width="24" height="24" viewBox="0 0 16 16" class="bi bi-clipboard-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
              <path fill-rule="evenodd" d="M9.5 1h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3zm-.354 7.146a.5.5 0 0 1 .708 0L8 8.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 9l1.147 1.146a.5.5 0 0 1-.708.708L8 9.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 9 6.146 7.854a.5.5 0 0 1 0-.708z"/>
            </svg>
        </Button>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Piikkaushistorian pyyhkiminen
          </Modal.Title>
        </Modal.Header>
          <Modal.Body>
            Haluatko varmasti pyyhkiä piikkaushistorian? <br/>
            (Tämä vaikuttaa myös tilastoihin)
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger shadow-sm"
              onClick={() => handleClearSpikes()}
            >
              Varmista
            </Button>
          </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default ClearSpikesButton;