import React, {Fragment} from 'react';
import ListUserSpikes from './ListUserSpikes';
import CreateChargeButton from './CreateChargeButton';
import ClearSpikesButton from './ClearSpikesButton';
import DeleteUserButton from './DeleteUserButton';
import HostStatusSwitch from './HostStatusSwitch';
import SendMailButton from './SendMailButton';

const HostViewUserInfo = ({spikee}) => {
  
  return (
    <Fragment>
      <table width="100%">
        <tr width="100%">
          <td align="center">
            <HostStatusSwitch user={spikee} />
          </td>
          <td align="center">
            <CreateChargeButton spikees={[spikee]} /> 
          </td>
          <td align="center">
            <SendMailButton spikees={[spikee]} /> 
          </td>
          <td align="center">
            <ClearSpikesButton spikees={[spikee]} />
          </td>
          <td align="center">
            <DeleteUserButton user={spikee} />
          </td>
      </tr>
      </table>
      <h3 
        style={{ width:"100%", backgroundColor:"#373433", color:"white"}}
        className="mb-0 px-2">
        Piikkaushistoria: {spikee.name}
      </h3>
      <ListUserSpikes spikee={spikee} />
    </Fragment>
  )
}

export default HostViewUserInfo;