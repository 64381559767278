import {useThunkReducer} from 'react-hook-thunk-reducer';
import {createContainer} from 'react-tracked';
import {masterReducer, initialState} from './reducers/masterReducer';

const useValue = () => useThunkReducer(masterReducer, initialState);
//console.log(useValue);

export const { 
  Provider, 
  useTrackedState, 
  useUpdate: useDispatch 
} = createContainer(useValue); 