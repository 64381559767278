import React, {Fragment, useState, useEffect} from 'react';
import {Spinner, Button, Tabs, Tab, ButtonGroup, ToggleButton} from 'react-bootstrap';
import Chart from 'react-apexcharts';
import ListUserSpikes from './ListUserSpikes';
import { CSVLink } from "react-csv";
import moment from 'moment';
import {useTrackedState} from '../store';
import {priceFormatter, round} from './formatterFunctions';

const ProductStats = ({spikee = null}) => {
  const {spikes, categories, products, loading} = useTrackedState();
  const [dateArray, setDateArray] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [toggleVal, setToggle] = useState('category')

  const toggleOptions = [
    {name: 'Kategorioittain', value: 'category'},
    {name:'Tuotteittain', value: 'product'}
  ]

  var spikesView = [];
  if (spikee !== null) {
    spikesView = spikes.filter(s => s.spikee_id === spikee.user_id);  
  }
  else {
    spikesView = spikes;
  }

  const setTimeResolution = (d) => {  
    return d.toDateString();
  };

  const maxProdId = products.reduce((r, n) => n.product_id > r ? n.product_id : r, 0);

  const allProductIds = 
    !(maxProdId > 0) ? 
      [] 
    :
      [...Array(maxProdId).keys()].map(id => id + 1)
  ;

  //gets product ids that have been spiked or massSpiked
  const uniqueSpikedProductIds = allProductIds.filter(id => 
      spikesView.map(s => s.product_id).includes(id) || 
      spikesView.map(s => s.product_id).includes(id * 1000000)
    )  

  var productStatistics = uniqueSpikedProductIds.map(id => {
      const productSpikes = spikesView.filter(s => s.product_id === id);
      const massSpikes = spikesView.filter(s => s.product_id === id * 1000000).map(s => {
        return (
          {
            ...s,
            date: setTimeResolution(s.time), 
            amount: parseInt(s.product_name.match(/(\d+)/)) 
          }
        );
      });

      const productCancels = spikesView.filter(s => s.product_id === id * -1);
      const massCancels = spikesView.filter(s => s.product_id === id * -1000000).map(s => {
        return (
          {...s,
            date: setTimeResolution(s.time), 
            amount: parseInt(s.product_name.match(/(\d+)/)) 
          }
        );
      });
      
      const productProfile = products.find(p => p.product_id === id)

      //if the product still exists, use it's current name and category
      let productName= '';
      let productCategory = '';
      let categoryProfile = null;
      if (productProfile) {
        productName = productProfile.name;
        categoryProfile = categories.find(c => c.category_id === productProfile.category_id)
      }
      //otherwise, use the last name and category it was spiked with before deletion
      else {
        productName = productSpikes.sort((a, b)=>a.time - b.time).map(ps => ps.product_name)[productSpikes.length - 1]
      }

      if (categoryProfile) {
        productCategory = categoryProfile.category;
      }
      else {
        productCategory = productSpikes.sort((a, b)=>a.time - b.time).map(ps => ps.category)[productSpikes.length - 1]
      }

      return (
        {
          product: productName,
          category: productCategory,
          data: dateArray.map(d => {
            return (
              {
                x: d,
                y: Math.max(0,
                    productSpikes.filter(ps => setTimeResolution(ps.time) === d).length 
                    + massSpikes.filter(ma => ma.date === d).map(ma => ma.amount).reduce((r,n) => r+n, 0)
                    - productCancels.filter(c => setTimeResolution(c.time) === d).length
                    - massCancels.filter(ma => ma.date === d).map(ma => ma.amount).reduce((r,n) => r+n, 0)
                  )
              }
            )
          }),
          totalSold: productSpikes.length 
            + massSpikes.map(ms => ms.amount).reduce((r, n) => r+n, 0) 
            - productCancels.length
            - massCancels.map(ms => ms.amount).reduce((r, n) => r+n, 0),
          totalRevenue : -1 * ( productSpikes.map(ps => parseFloat(ps.change)).reduce((r,n) => r+n, 0)
            + massSpikes.map(ms => parseFloat(ms.change)).reduce((r,n) => r+n, 0)
            + productCancels.map(ps => parseFloat(ps.change)).reduce((r,n) => r+n, 0)
            + massCancels.map(ms => parseFloat(ms.change)).reduce((r,n) => r+n, 0))
            
        }
      )
    }
  );

  productStatistics = productStatistics.filter(p => p.totalSold > 0);
  const categoryStatistics = productStatistics.map(p => {
      return (
        { category:p.category,
          totalSold: 
            productStatistics.filter(p2 => p2.category === p.category)
            .reduce((r,n)=>r + n.totalSold, 0),
          totalRevenue: 
            productStatistics.filter(p2 => p2.category === p.category)
            .reduce((r,n)=>r + n.totalRevenue, 0),
        }
      )
    }).filter((v, i, a) => a.findIndex(t=>(t.category === v.category)) === i);


  //const productTimelineSeries = productStatistics.map(p => {return ({name: p.name, data: p.data});});

  //console.log(productPieSeries.map(p => p.revenue));

  const colors = ['#feb116', 
                  '#585856', 
                  '#5abbc0', 
                  '#cac6bd', 
                  '#f78b82', 
                  '#9f9e9a', 
                  '#0B6E4F',
                  '#BF9ACA', 
                  '#5E7CE2', 
                  '#FDE12D', 
                  '#D4CDF4', 
                  '#D17B18', 
                  '#81C14B', 
                  '#F7D488',
                  '#CC5803', 
                  '#774936',
                  '#585123',
                  '#E3EA99',];

  var timelineOptions = {
    chart: {
      type: 'line',
      stacked: false,
      toolbar: {
        show: false
      },
      zoom: {
        type: 'x',
        enabled: false,
        autoScaleYaxis: false
      },
      events: {
        click: (event, chartContext, { seriesIndex, dataPointIndex, config}) => {
          if (seriesIndex !== -1 && dataPointIndex !== -1) // do nothing special if legend was clicked
            setSelectedDate((new Date(chartContext.data.twoDSeriesX[dataPointIndex])));
        }
      }
    },
    noData: {
      text: 'Piikki on tyhjä.',
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight',
    },
    markers: {
      size: 0,
      hover: {
        size: 7
      },
    },
    yaxis: {
      title: {
        text: 'Kpl'
      },
      labels: {
        formatter: function(val, index) {
          if (val === undefined) return val;
          return val % 1 === 0 ? val.toFixed(0) : val.toFixed(2);
        }
      }
    },
    xaxis: {
      type: 'datetime',
      title: 'Aika',
      labels: {
        formatter: function(val, timestamp) {
          return moment(timestamp).format("DD.MM.YYYY");
        }
      }
    },
    /*theme: {
      palette:'palette1'
    },*/
    colors: colors,
    tooltip: {
      shared: true,
      fixed: {
        enabled: true,
        position: 'topRight'
      },
      y: {
        formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
          return value > 0 ? value : null;
        },
        title: (seriesName) => {
          return this.y > 0 ? seriesName : null;
        }
      }
    }
  };

  var soldPieOptions = {
    chart: {
      type: 'pie',
    },
    noData: {
      text: 'Piikki on tyhjä.',
    },
    labels: toggleVal === 'product' ?
      productStatistics.sort((a, b) => (a.totalSold > b.totalSold) ? -1 : 1).map(s => s.product)
       : 
      categoryStatistics.sort((a, b) => (a.totalSold > b.totalSold) ? -1 : 1).map(s => s.category),
    legend: {
      position: 'bottom'
    },
    colors: colors,
    tooltip: {
      y: {
        formatter: function (value) {
          return value + ' piikkausta'
        }
      }
    }
  };

  var revenuePieOptions = {
    chart: {
      type: 'pie',
    },
    noData: {
      text: 'Piikki on tyhjä.',
    },
    labels: toggleVal === 'product' ?
      productStatistics.sort((a, b) => (a.totalRevenue > b.totalRevenue) ? -1 : 1).map(s => s.product)
       : 
      categoryStatistics.sort((a, b) => (a.totalRevenue > b.totalRevenue) ? -1 : 1).map(s => s.category),
    legend: {
      position: 'bottom'
    },
    colors: colors,
    tooltip: {
      y: {
        formatter: function (value) {
          return round(value) + ' €'
        }
      }
    }
  };

  useEffect(() => {
    const oldestSpike = Math.min.apply(null, spikesView.map(s => s.time));
    var runningDate = new Date(oldestSpike);
    const today = new Date();
    today.setDate(today.getDate() + 1);
    var days = new Array();
    while (runningDate < today) {
      days.push(setTimeResolution(runningDate));
      runningDate.setDate(runningDate.getDate() + 1);
    }
    setDateArray(days);
  }, [spikes])

  const selectedDateTitleOptions = {
    weekday: 'long', 
    year: 'numeric', 
    month: 'numeric', 
    day: 'numeric'
  };

  const csvHeaders = [
    { label: "Aika", key: "time" },
    { label: "Kategoria", key: "category" },
    { label: "Tuote", key: "product_name" },
    { label: "Piikattava", key: "spikee_name" },
    // { label: "Piikattava_ID", key: "spikee_id" },
    { label: "Piikkaaja", key: "spiker_name" },
    // { label: "Piikkaaja_ID", key: "spikee_id" },
    // { label: "Tuote_ID", key: "product_id" },
    { label: "Muutos (€)", key: "change" },
    { label: "Uusi Saldo (€)", key: "credit_after_spike" }
  ];
  const csvFilename = "ePiikki-tilastoja.csv";

  return (
    <div style={{height: 1200}}>
      {(loading.spikes || loading.products) ? 
        <Spinner className="m-3 float-left" size="sm" animation="border" role="status"/> 
      : 
        <Fragment>
          <div>
          <h2 className='pt-4'>Tuotetilastoja</h2>
          <CSVLink data={spikesView} headers={csvHeaders} filename={csvFilename}>
            <Button variant="dark" className="float-right shadow-sm" alt="Lataa tilastot .csv-tiedostona">
            <svg viewBox="0 0 24 24" height="24" width="24" fill="currentColor">
              <path d="M11.2,16.6c0.4,0.5,1.2,0.5,1.6,0l6-6.3C19.3,9.8,18.8,9,18,9h-4c0,0,0.2-4.6,0-7c-0.1-1.1-0.9-2-2-2c-1.1,0-1.9,0.9-2,2    c-0.2,2.3,0,7,0,7H6c-0.8,0-1.3,0.8-0.8,1.4L11.2,16.6z"/>
              <path d="M19,19H5c-1.1,0-2,0.9-2,2v0c0,0.6,0.4,1,1,1h16c0.6,0,1-0.4,1-1v0C21,19.9,20.1,19,19,19z"/>
            </svg>
            </Button>
          </CSVLink>
          </div>
          <Tabs
            defaultActiveKey="timeline"
            className="mt-3"
            style={{borderStyle:"none"}}
            width="100%"
          >
            <Tab id="statsTab" eventKey="timeline" title="Aikajana">
              <div id="statTabInnards">
              <h4 width="100%" className="p-3">Piikkaukset aikajanalla:</h4>
              <Chart 
                options={timelineOptions}
                series={productStatistics.map(p => {return ({name: p.product, data: p.data});})}
              />
              {selectedDate === null ? null :
                <Fragment>
                <div align="center">
                  <tr>
                    <td align="right">
                  <Button
                    className="m-3 shadow-sm"
                    variant="dark"
                    onClick={() => setSelectedDate( new Date(selectedDate.setDate(selectedDate.getDate() - 1)))}
                  >
                    <b>&larr;</b>
                  </Button>
                  </td>
                  <td width="80%">
                  <h3>{selectedDate.toLocaleDateString('fi-FI', selectedDateTitleOptions)}</h3>
                  </td>
                  <td align="left">
                  <Button
                    className="m-3 shadow-sm"
                    variant="dark"
                    onClick={() => setSelectedDate( new Date(selectedDate.setDate(selectedDate.getDate() + 1)))}
                  >
                    <b>&rarr;</b>
                  </Button>
                  </td>
                  </tr>
                </div>
                <ListUserSpikes 
                  spikee={spikee}
                  className="pb-3"
                  dateGiven={setTimeResolution(selectedDate)}
                />
                </Fragment>
              }
              </div>
            </Tab>
            <Tab id="statsTab" style={{borderBottom:0}} eventKey="pie1" title="Piikit">
              <div id="statTabInnards">
              <h4 width="100%" className="p-3">Piikkausmäärien jakauma:</h4>
              <ButtonGroup toggle className="mx-2">
                {toggleOptions.map((opt, idx) => (
                  <ToggleButton
                    key={idx}
                    type="radio"
                    variant="outline-dark"
                    name="split"
                    value={opt.value}
                    checked={toggleVal === opt.value}
                    onChange={(e) => setToggle(e.currentTarget.value)}
                  >
                    {opt.name}
                  </ToggleButton>
                ))}
              </ButtonGroup>
              <div align="center">
              <Chart 
                options={soldPieOptions}
                className="pb-3"
                type="pie"
                series={toggleVal === 'product' ?
                  productStatistics.sort((a, b) => (a.totalSold > b.totalSold) ? -1 : 1).map(s => s.totalSold) 
                    : 
                  categoryStatistics.sort((a, b) => (a.totalSold > b.totalSold) ? -1 : 1).map(s => s.totalSold)}
                //labels={productStatistics.map(s => toggleVal === 'product' ? s.product : s.category)}
                width="90%"
                legend={{position:"bottom"}}
              />
              <h3 className="my-3 pb-3"><strong>Yhteensä {productStatistics.reduce((r, n) => r + n.totalSold, 0)} piikkausta</strong></h3>
              </div>
              </div>
            </Tab>
            <Tab id="statsTab" style={{borderBottom:0}} eventKey="pie2" title="Raha">
              <div id="statTabInnards">
              <h4 width="100%" className="p-3">Rahanvirran jakauma:</h4>
              <ButtonGroup className="mx-2" toggle>
                {toggleOptions.map((opt, idx) => (
                  <ToggleButton
                    key={idx}
                    type="radio"
                    variant="outline-dark"
                    name="split"
                    value={opt.value}
                    checked={toggleVal === opt.value}
                    onChange={(e) => setToggle(e.currentTarget.value)}
                  >
                    {opt.name}
                  </ToggleButton>
                ))}
              </ButtonGroup>
              <div align="center">
              <Chart 
                options={revenuePieOptions}
                type="pie"
                className="pb-3"
                series={toggleVal === 'product' ?
                  productStatistics.sort((a, b) => (a.totalRevenue > b.totalRevenue) ? -1 : 1).map(s => s.totalRevenue)
                   : 
                  categoryStatistics.sort((a, b) => (a.totalRevenue > b.totalRevenue) ? -1 : 1).map(s => s.totalRevenue)}
                //labels={productStatistics.map(s => toggleVal === 'product' ? s.product : s.category)}
                width="90%"
                legend={{position:"bottom"}}
              />
              <h3 className="my-3 pb-3"><strong>Yhteensä</strong> {priceFormatter(productStatistics.reduce((r, n) => r + n.totalRevenue, 0))}</h3>
              </div>
              </div>
            </Tab>
          </Tabs>
        </Fragment>}
    </div>
  )
};

export default ProductStats;