import React, {Fragment, useState} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {Form, Button, Modal, Spinner} from 'react-bootstrap';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {nameFormatter} from './formatterFunctions';
import {useDispatch, useTrackedState} from '../store';
import {updateUserEmail, updateUserPassword} from '../reducers/userListActionCreators';
import { ERROR } from '../reducers/masterReducer';

const UserInfo = () => {
  const dispatch = useDispatch();
  const {currentUser} = useTrackedState();
  const [oldEmail, setOldEmail] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [showPwdModal, setShowPwdModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);

  function validEmail(userEmail) {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userEmail);
  }

  const handleNewPassword = () => {
    try {
      if (newPassword !== confirmNewPassword) {
        dispatch({type:ERROR, message:"Uudet salasanat eivät täsmää"})
        return;
      }
      dispatch(updateUserPassword(currentUser.user_id, oldPassword, newPassword));
    } catch (err) {
      console.error(err.message);
    }
  }

  const handleNewEmail = () => {
    try {
      dispatch(updateUserEmail(currentUser.user_id, oldPassword, oldEmail, newEmail ));
      setShowEmailModal(false);
    } catch (err) {
      console.error(err.message);
    }
  }

  const columns = [
    {
      dataField: 'name',
      text: 'Nimi',
      editable: false,
      headerStyle: {width: '30%', backgroundColor:"whitesmoke"},
      style: {overflowWrap:'break-word'},
      formatExtraData: {nameFormatter},
      formatter: (cell, row, rowIndex, {nameFormatter}) => {
        return (
          <div align="center" className="mt-2">
            {nameFormatter(cell)}
          </div>
        )
      },
      headerAlign:'center'
    }, {
      dataField: 'email',
      text: 'Sähköposti',
      editable: true,
      headerStyle: {width: '45%', backgroundColor:"whitesmoke"},
      style: {overflowWrap:'break-word'},
      validator: (newValue) => {
        if (!validEmail(newValue)) {
          return {
            valid: false,
            message: 'Virheellinen sähköpostiosoite'
          };
        }
        return true;
      },
      formatter: (cell) => {
        const cells = cell ? cell.split("@") : '';
        return (
        <div overflowwrap='break-word' className="mt-2 shadow-sm">
            <div 
              align="center" 
              style={
                {
                  border:"1px solid lightgray", 
                  borderRadius:"3px" 
                }
              }
            >
              {cells[0]} <br/>
              @{cells[1]}
            </div>
        </div>)
      },
      headerAlign:'center'
    }, {
      dataField: 'dummypassword',
      isDummyField: true,
      text: 'Salasana',
      editable: false,
      headerStyle: {width: '25%', backgroundColor:"whitesmoke"},
      style: {overflowWrap:'break-word'},
      formatExtraData: setShowPwdModal,
      formatter: (cell, row, rowIndex, setShowModal) => {
        return (
        <Fragment>
          <div
            align="center" 
            className="mt-2 shadow-sm" 
            onClick={() => setShowModal(true)}
            style={
              {
                border:"1px solid lightgray", 
                borderRadius:"3px" 
              }
            }>
            <b>•••••••</b>
          </div>
        </Fragment>)
      },
      headerAlign:'center'
    }
  ];

  const cellEdit = cellEditFactory({
    mode: 'click',
    autoSelectText: true,
    blurToSave:true,
    beforeSaveCell: (oldValue, newValue, row, column, done) => {
        if (oldValue !== newValue) { 
          setOldEmail(oldValue);
          setNewEmail(newValue);
          setShowEmailModal(true);
        }
        done({async: false});
        return;
    }
  });

 return (
   <Fragment>
    {(!currentUser) ? 
      <Spinner className="m-3 float-left" size="sm" animation="border" role="status"/>
     : 
    <Fragment>
      <BootstrapTable 
        keyField='user_id'
        className="my-5"
        columns={columns}
        data={[currentUser]}
        bootstrap4
        style={{width:'100%'}}
        rowStyle={ { height: '65px' } }
        bordered={false}
        cellEdit={cellEdit}
      />
      <Modal show={showEmailModal} onHide={() => setShowEmailModal(false)}>
        <Modal.Header closeButton >
          <Modal.Title>Sähköpostiosoitteen vaihto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Muutetaan sähköpostiosoitteesi <br/>
  osoitteesta <b>{oldEmail}</b><br/>
  osoitteeseen <b>{newEmail}</b>.
          <Form>
            <Form.Row>
              <Form.Label className="mt-3">Vahvista muutos antamalla salasanasi:</Form.Label>
              <Form.Control 
                type="password"
                className="m-3"
                onChange={(e)=> setOldPassword(e.target.value)} 
              />
            </Form.Row>
            Muutoksen onnistuttua, molempiin osoitteisiin lähetetään vahvistusviesti.
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleNewEmail}
            variant="success"
          >
            Tallenna
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showPwdModal} onHide={()=>setShowPwdModal(false)}>
        <Modal.Header closeButton >
          <Modal.Title>Salasanan vaihto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Row>
              <Form.Label className="mt-3">Nykyinen salasana</Form.Label>
              <Form.Control 
                type="password" 
                onChange={(e) => setOldPassword(e.target.value)}/>
            </Form.Row>
            <Form.Row>
            <Form.Label className="mt-3">Uusi salasana</Form.Label>
              <Form.Control 
                type="password" 
                onChange={(e) => setNewPassword(e.target.value)}/>
            </Form.Row>
            <Form.Row>
            <Form.Label className="mt-3">Vahvista uusi salasana</Form.Label>
              <Form.Control 
                type="password" 
                onChange={(e) => setConfirmNewPassword(e.target.value)}/>
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleNewPassword}
            variant="success"
          >
            Tallenna
          </Button>
        </Modal.Footer>
      </Modal> 
    </Fragment>}
    
   </Fragment>
 )
};

export default UserInfo;