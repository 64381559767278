import React, {Fragment, useState, useEffect} from 'react';
import {Spinner} from 'react-bootstrap';
import LazyLoad from 'react-lazyload';
import Fade from 'react-reveal/Fade';
import scrollUpArrow from '../assets/scrollUpArrow.gif';
import ProductStats from './ProductStats';
import ListUserSpikes from './ListUserSpikes';
import {useTrackedState} from '../store';
import squiggle from '../assets/squiggle1.png';
import hor_line from '../assets/hor_line.png';

const HostViewStats = () => {
  const {loading} = useTrackedState();
  
  return (
    <div id="tabInnards"> 
      <LazyLoad 
            height={500} 
            placeholder={
              <div width="100%" style={{minHeight:500}} align="center">
                {loading.spikes || loading.products || loading.userList ?
                    <Spinner className="m-3" animation="border" role="status"/>
                      :
                      <img src={scrollUpArrow} height={50} alt="Vieritä alas ladataksesi lisää sivua"/>} 
              </div>
            }
          >
        <Fade duration={500}>
          <h3 className="pt-4">Edellisen 7 päivän piikkaukset:</h3>
          <ListUserSpikes dateRangeGiven={7} />
        </Fade>
      </LazyLoad>
      
        <LazyLoad 
            height={1500} 
            placeholder={
              <div width="100%" style={{minHeight:1500}} align="center">
                {loading.spikes ?
                    <Spinner className="m-3" animation="border" role="status"/>
                      :
                      <img src={scrollUpArrow} height={50} alt="Vieritä alas ladataksesi lisää sivua"/>} 
              </div>
            }
          >
        <Fade duration={500}>
          <ProductStats />
        </Fade>
      </LazyLoad>
    </div>
  );
};

export default HostViewStats;