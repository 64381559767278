import {toast} from 'react-toastify';

import {
  FETCH_CATEGORIES,
  ADD_CATEGORY,
  DELETE_CATEGORY,
  SWITCH_CATEGORY_RANKS,
  stateNames,
  SET_TO_LOADING,
  ERROR,
  RENAME_CATEGORY,
  apiUrl
} from './masterReducer'

const baseUrl = apiUrl + "/categories";

export const fetchCategories = () => async (dispatch) => {
  try {
    dispatch({type:SET_TO_LOADING, setLoad:stateNames.categories});
    const response = await fetch(baseUrl, {
      headers: {
        "jwtToken": localStorage.jwtToken
      }
    });
    const jsonData = await response.json();
    dispatch({type:FETCH_CATEGORIES, categories: jsonData});
  } catch (err) {
    dispatch({type:ERROR, message: err.message});
  }
};

export const addCategory = (new_category) => async (dispatch) => {
  try {
    dispatch({type:SET_TO_LOADING, setLoad:stateNames.categories});
    const response = await fetch(baseUrl,
      {
        method: 'POST',
        headers: 
          {
            "jwtToken": localStorage.jwtToken,
            "Content-Type": "application/json"
          },
          body:JSON.stringify({new_category:new_category})
      }  
    );
    const jsonData = await response.json();
    dispatch({type:ADD_CATEGORY, new_category: jsonData});
    toast.success(`Uusi kategoria luotu: ${new_category.category}`)
  } catch (err) {
    dispatch({type:ERROR, message: err.message});
  }
};

export const switchCategoryRanks = (categories_to_switch) => async (dispatch) => {
  try {
    dispatch({type:SET_TO_LOADING, setLoad:stateNames.categories});
    const response = await fetch(baseUrl, 
      {
        method: 'PUT',
        headers: {
          "jwtToken": localStorage.jwtToken,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({categories_to_switch:categories_to_switch})
      }
    );
    const jsonData = await response.json();
    //console.log(jsonData);
    dispatch({type:SWITCH_CATEGORY_RANKS, categories_to_switch:categories_to_switch});
  } catch (err) {
    dispatch({type:ERROR, message: err.message});
  }
};

export const renameCategory = (category_id, new_name) => async (dispatch) => {
  try {

    dispatch({type:SET_TO_LOADING, setLoad:stateNames.categories});
    dispatch({type:SET_TO_LOADING, setLoad:stateNames.products});
    const response = await fetch(`${baseUrl}/${category_id}`,
      {
        method: 'PUT',
        headers: {
          "jwtToken": localStorage.jwtToken,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(
          { 
            category_id: category_id, 
            new_name: new_name
          }
        )
      }
    );
    const jsonData = await response.json();
    //console.log(jsonData);
    dispatch({type:RENAME_CATEGORY, category_id: category_id, new_name: new_name});
    toast.success("Kategoria uudelleennimetty");
  } catch (err) {
    dispatch({type:ERROR, message: err.message});
  }
}

export const deleteCategory = (category_id) => async (dispatch) => {
  try {
    dispatch({type:SET_TO_LOADING, setLoad:stateNames.categories});
    const response = await fetch(`${baseUrl}/${category_id}`,{
        method: 'DELETE',
        headers: {
          "jwtToken": localStorage.jwtToken
        }
    });
    const jsonData = await response.json();
    //console.log(jsonData);
    dispatch({type:DELETE_CATEGORY, category_id:jsonData.category_id, rank: jsonData.rank});
    toast.success(`Kategoria poistettu`);
  } catch (err) {
    dispatch({type:ERROR, message: err.message});
  }
}
