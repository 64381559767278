import React, {Fragment} from 'react';
import scrollUpArrow from '../assets/scrollUpArrow.gif';
import Fade from 'react-reveal/Fade';
import LazyLoad from 'react-lazyload';
import ListUserSpikes from './ListUserSpikes';
import UserInfo from './UserInfo';
import ProductStats from './ProductStats';
import {Spinner, Button} from 'react-bootstrap';
import {moneyFormatter} from './formatterFunctions';
import {useTrackedState, useDispatch} from '../store';
import {logout} from '../reducers/authActionCreators'; 
import squiggle from '../assets/squiggle1.png';
import hor_line from '../assets/hor_line.png';

const Profile = () => {
  const {currentUser, userList, loading} = useTrackedState();
  const dispatch = useDispatch();


  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logout());
  }

  const currentUserInfo = userList.find(u => u.user_id === currentUser.user_id);
  
  return(
    <div id="tabInnards">
      {(loading.currentUser || loading.userList || !currentUserInfo) ? 
        <Spinner className="m-3 float-left" size="sm" animation="border" role="status"/>
      :
        <Fragment>
          <div width="100%" >
            <Button 
              className="btn mx-3 my-3 float-left shadow-sm"
              variant="light"
              onClick={(e) => handleLogout(e)}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                <path d="M16,17V14H9V10H16V7L21,12L16,17M14,2A2,2 0 0,1 16,4V6H14V4H5V20H14V18H16V20A2,2 0 0,1 14,22H5A2,2 0 0,1 3,20V4A2,2 0 0,1 5,2H14Z" />
              </svg>
            </Button>
            <h2 align="center" className="py-3" >Saldo:{moneyFormatter(currentUser.credit)}</h2>
          </div>
          <UserInfo />
          <LazyLoad 
            height={500} 
            placeholder={
              <div width="100%" style={{minHeight:500}} align="center">
                {loading.spikes || loading.products || loading.userList || loading.currentUser || !currentUser ?
                    <Spinner className="m-3" animation="border" role="status"/>
                      :
                      <img src={scrollUpArrow} height={50} alt="Vieritä alas ladataksesi lisää sivua"/>} 
              </div>
            }
          >
            <Fade duration={500}>
            <table align="center">
              <tbody>
              <tr>
              <td width="45%" align="center">
                <img src={hor_line} width="100%" className="mx-1" style={{backgroundColor:"white"}} alt=""/>
              </td>
              <td>
              <img className="my-3 mx-2" src={squiggle} width={50} alt="" />
              </td>
              <td width="45%" align="center">
                <img src={hor_line} width="100%" className="mx-1" style={{backgroundColor:"white"}} alt=""/>
              </td>
              </tr>
              </tbody>
            </table>
            <h3 
              width="100%" 
              //style={{backgroundColor: "#373433", color: "white"}}
              className="my-2 px-2">
              Omat piikkaukset:
            </h3>
              <ListUserSpikes spikee={currentUserInfo}/>
            </Fade>
          </LazyLoad>
          <LazyLoad 
            height={1200} 
            placeholder={
              <div width="100%" style={{minHeight:1200}} align="center">
                {loading.spikes || loading.products || loading.userList || loading.currentUser || !currentUser ?
                    <Spinner className="m-3" animation="border" role="status"/>
                      :
                      <img src={scrollUpArrow} height={50} alt="Vieritä alas ladataksesi lisää sivua"/>} 
              </div>
            }
          >
            <Fade duration={500}>
            <table align="center">
              <tbody>
              <tr>
              <td width="45%" align="center">
                <img src={hor_line} width="100%" className="mx-1" style={{backgroundColor:"white"}} alt=""/>
              </td>
              <td>
              <img className="my-3 mx-2" src={squiggle} width={50} alt="" />
              </td>
              <td width="45%" align="center">
                <img src={hor_line} width="100%" className="mx-1" style={{backgroundColor:"white"}} alt=""/>
              </td>
              </tr>
              </tbody>
            </table>
              <ProductStats spikee={currentUserInfo} />
            </Fade>
          </LazyLoad>
        </Fragment>
      }
    </div>
  )
};

export default Profile;