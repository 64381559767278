import React, {Fragment, useState} from 'react';
import {Button, Modal,Form, Row, Col} from 'react-bootstrap';
import { moneyFormatter } from './formatterFunctions';
import {useDispatch, useTrackedState} from '../store';
import {addCharge} from '../reducers/spikesActionCreators';

const CreateChargeButton = ({spikees, DeSelectSpikees = null}) => {
  const {currentUser} = useTrackedState();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [charge, setCharge] = useState({amount:'', name:''});
  
  //flag whether multiple users are being 'zeroed out', in which case
  //the charge amounts will be variable to each spikee
  const [multipleZeroFlag, setMultipleZeroFlag] = useState(false);
  const [singleZeroFlag, setSingleZeroFlag] = useState(false);
  const [errors, setErrors] = useState({name: false, charge: false});

  const handleShowModal = () => setShow(true);
  
  const handleCloseModal = () => {
    setShow(false);
    setCharge({amount:'', name:''});
    setMultipleZeroFlag(false);
    setSingleZeroFlag(false);
    setErrors({name: false, amount:false});
  };
  

  function validateCharge (val) {
    const exp = /^[+-]?[0-9]+\.?[0-9]*$/;
    return multipleZeroFlag || val.match(exp);
  }

  const handleSubmit = async() => {
    try {
      setCharge({...charge, name: charge.name.trim()});
      const nameError = (charge.name.trim() === '');
      const chargeError = (!validateCharge(charge.amount));

      if (nameError || chargeError) {
        console.log("There's errors")
        setErrors(
          {
            name: nameError, 
            amount: chargeError
          }
        );
        return;
      }
      const chargeData = {
        spikees : spikees,
        spiker_name : currentUser.name,
        spiker_id : currentUser.user_id,
        charge_amount: charge.amount,
        product_name: charge.name,
        multipleZeroFlag: multipleZeroFlag
      };

      console.log("IMPLEMENT CHARGES");
      dispatch(addCharge(chargeData));
      handleCloseModal();
      if (DeSelectSpikees) DeSelectSpikees();

    } catch (err) {
      console.error(err.message)
    }
  }

  const getZeroBalance = (e) => {
    console.log(singleZeroFlag);
    console.log(multipleZeroFlag);
    if (singleZeroFlag || multipleZeroFlag) {
        setSingleZeroFlag(false);
        setMultipleZeroFlag(false);
        return "";
    }
    setSingleZeroFlag(!singleZeroFlag);
    console.log(charge);
    if (spikees.length === 1) {
      const sign = spikees[0].credit < 0 ? '+' : '';
      return sign + ((Math.round(spikees[0].credit * -100) / 100).toFixed(2));
    }
    setMultipleZeroFlag(true);
    return '***Usean tilin nollaus***';

  }

  return (
    <Fragment>
      <Button 
        variant={spikees.length === 0 ? "outline-secondary" : "dark shadow-sm"} 
        className="m-3"
        disabled={spikees.length === 0}
        onClick={handleShowModal}
        title={spikees.length === 0 ? "Valitse yksi tai useampi käyttäjä" : null}
      >
        <svg width="24" height="24" viewBox="0 0 16 16" class="bi bi-cash-stack" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path d="M14 3H1a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1h-1z"/>
          <path fill-rule="evenodd" d="M15 5H1v8h14V5zM1 4a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H1z"/>
          <path d="M13 5a2 2 0 0 0 2 2V5h-2zM3 5a2 2 0 0 1-2 2V5h2zm10 8a2 2 0 0 1 2-2v2h-2zM3 13a2 2 0 0 0-2-2v2h2zm7-4a2 2 0 1 1-4 0 2 2 0 0 1 4 0z"/>
        </svg>
      </Button>
      <Modal show={show} onHide={handleCloseModal}>
        <Modal.Header closeButton >
          <Modal.Title>Muuta saldoa</Modal.Title>
        </Modal.Header>
        <Form>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Label>Saldomuutos</Form.Label>
              <Form.Control 
                placeholder="+/- 0.00" 
                value={charge.amount} 
                disabled={singleZeroFlag || multipleZeroFlag}
                onChange={
                  e => {
                    setMultipleZeroFlag(false);
                    setSingleZeroFlag(false);
                    setCharge({...charge, amount: e.target.value});
                  }
                }/>
            {!errors.amount ? null : 
              <div className="text-danger sm"><i>Anna muutos desimaalilukuna</i></div>
            }
              <Form.Switch
                id="zeroSwitch"
                className="m-2"
                label="Nollaa saldo(t)"
                checked={multipleZeroFlag || singleZeroFlag}
                onChange={(e)=>{
                  setCharge(
                    {
                      ...charge, 
                      amount: getZeroBalance(e)
                    }
                  );
                }
                }
              />
            </Col>
            <Col>
            <Form.Label>Kuvaus</Form.Label>
              <Form.Control 
                type="text"
                placeholder="..."
                value={charge.name}
                onChange={e=> setCharge({...charge, name: e.target.value})}
              />
              {!errors.name ? null : 
              <div className="text-danger sm"><i>Anna jokin kuvaus</i></div>
            }
            </Col>
          </Row>
        </Modal.Body>
        </Form>
        <Modal.Footer>
          Saldomuutos: {moneyFormatter(parseFloat(charge.amount) ? parseFloat(charge.amount) : 0, null, true)}
          <Button 
            variant={charge.name==="" || !(parseFloat(charge.amount) || multipleZeroFlag) ? "outline-secondary" : "success shadow-sm"} 
            disabled={charge.name==="" || !(parseFloat(charge.amount) || multipleZeroFlag)}
            onClick={handleSubmit}>
            Tallenna
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
};

export default CreateChargeButton;