import React, { useState, Fragment, useEffect } from 'react';
import {Button, Modal, Form, Row, Col} from 'react-bootstrap';
import {useTrackedState, useDispatch} from '../store';
import ReactHtmlParser from 'react-html-parser';
import {round} from './formatterFunctions';
import {sendMail} from '../reducers/settingsActionCreators';
import { toast } from 'react-toastify';

const SendMailButton = ({spikees}) => {
  const {settings} = useTrackedState();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [selectedPreview, setSelectedPreview] = useState({});

  const templateToText = (s) => {
    return `
    <p>Moi ${s.name ? s.name.split(" ")[0] : '[KÄYTTÄJÄN NIMI]'}!</p>
    
    <p>${settings.message}</p>
    
    <table>
    <th>Maksutiedot</th>
    <tr><td>Vastaanottaja:</td> <td> ${settings.payee} </td> </tr>
    <tr><td>Tilinumero:</td> <td> ${settings.bank_account} </td></tr>
    <tr><td>Viitenumero/Maksuviesti:</td> <td> ${settings.reference} </td></tr>
    <tr><td>Maksettava summa:</td> <td> ${s.credit ? round(s.credit *-1) : '[KÄYTTÄJÄN SALDO]'} €</td></tr>
    </table>`
  }

  const compriseMails = () => {
    if (spikees.length > 0) {
      const mailList = spikees.map(
        s => {
          return {
            recipient: s.email, 
            content: templateToText(s)
          }
        }
      )
      return mailList;
    }
  }

  const handleSubmit = async () => {
    try {
      const mails = compriseMails();
      dispatch(sendMail(settings.title, mails));
      setShowModal(false);
      toast.success("Lähetetään sähköposteja...",
        { 
          autoClose: (mails.length * 1000),
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          pauseOnFocusLoss: false
        })
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    compriseMails();
  }, [spikees])

  return(
    <Fragment>
      {spikees ? 
      <Button 
        className="m-3"
        disabled={spikees.length === 0}
        variant={spikees.length === 0 ? "outline-secondary" : "dark"}
        title={spikees.length === 0 ? "Valitse yksi tai useampi käyttäjä" : null}
        onClick={() => setShowModal(prevState => !prevState)}
      >
        <svg width="24" height="24" viewBox="0 0 16 16" class="bi bi-envelope-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"/>
        </svg>
      </Button> : null }
      <Modal  
        show={showModal} 
        onHide={() => setShowModal(false)} 
        size='lg'
        dialogClassName="modal-90w">
        <Modal.Header closeButton>
          <Modal.Title>Maksukehotteiden lähetys</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {spikees.length === 0 ? null :
          <Form> 
            <Row>
            <Col>
              <Form.Label><strong>Vastaanottajat:</strong></Form.Label>
              <Form.Control 
                as="select" 
                onChange={(e) => setSelectedPreview(spikees[e.target.value])}
                htmlSize={10} >
                {spikees.map((s, index) => {
                  return (
                    <option value={index}>
                    {s.name}: {s.email}
                    </option>
                  )
                })}
              </Form.Control>
            </Col>
            <Col xs={8}>
              <Form.Label><strong>Viestin esikatselu:</strong></Form.Label>
              <Form.Text>
              {ReactHtmlParser(templateToText(selectedPreview))}
              </Form.Text>
            </Col>
            </Row>
          </Form>
        }
        </Modal.Body>
        <Modal.Footer>
          <Button 
            className="m-3"
            variant="success"
            onClick={handleSubmit}
          >
            Lähetä sähköpostit
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )

};

export default SendMailButton;