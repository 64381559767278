import React, {Fragment, useState, useEffect} from 'react';
import LazyLoad from 'react-lazyload';
import Fade from 'react-reveal/Fade';
import scrollUpArrow from '../assets/scrollUpArrow.gif';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {moneyFormatter, nameFormatter} from './formatterFunctions';
import HostViewUserInfo from './HostViewUserInfo';
import CreateChargeButton from './CreateChargeButton';
import ClearSpikesButton from './ClearSpikesButton';
import SendMailButton from './SendMailButton';
import {useTrackedState} from '../store';
import ListUserSpikes from './ListUserSpikes';

const HostViewUsers = () => {
  const {userList, hosts} = useTrackedState();
  const [selectedUsers, setSelectedUsers] = useState([]); 
  const [saldo, setSaldo] = useState(null);

  const expandRow = {
    onlyOneExpanding: true,
    expandColumnPosition: 'right',
    renderer: spikee => {
      try {
        return (
          <HostViewUserInfo 
            spikee={spikee} //current user being viewed
          />
        )
      } catch (err) {
        console.error(err.message)
      }
    },
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <b>-</b>;
      }
      return <b>+</b>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <b>-</b>
        );
      }
      return (
        <b>...</b>
      );
    }
  };

  useEffect(() => {
    setSelectedUsers(userList.filter(
      u => selectedUsers.map(u2 => u2.user_id).includes(u.user_id)
    ));
  }, [userList])

  const columns = [{
    dataField: 'name',
    text: 'Nimi',
    sort: true,
    headerStyle: {width:'30%'},
    style: {overflowWrap:'break-word'},
    formatExtraData: hosts,
    formatter: (cell, row, rowIndex, hosts) => {
      const isHost = hosts.filter(h => h.host_id === row.user_id).length;
      return (
        <Fragment>
          <div align="left">
            {nameFormatter(cell + "   ")}
          {isHost ? 
            <svg  width="1.3em" height="1.3em" viewBox="0 0 16 16" class="bi bi-key-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M3.5 11.5a3.5 3.5 0 1 1 3.163-5H14L15.5 8 14 9.5l-1-1-1 1-1-1-1 1-1-1-1 1H6.663a3.5 3.5 0 0 1-3.163 2zM2.5 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
            </svg> : null}
          </div>
        </Fragment>
      )
    },
    headerAlign:'left'
  }, {
    dataField: 'email',
    text: 'Sähköposti',
    headerStyle: {width:'40%'},
    formatter: (cell) => {
      return (
        <div align="left">
          {cell}
        </div>
      )
    },
    headerAlign:'left', 
    style: {overflowWrap:'break-word'},
    sort: true
  }, {
    dataField: 'credit',
    text: 'Saldo',
    headerStyle: {width:'17%'},
    sort: true,
    sortFunc: (a, b, order) => {  
      if (order === "asc") return a - b; 
      return b-a; },
    formatExtraData: moneyFormatter,
    formatter: (cell, row, rowIndex, moneyFormatter) => {
      return (
        <div align="right">
          {moneyFormatter(cell)}
        </div>
      )
    },
    headerAlign:'right'
  }];

  const defaultSorted = [{
    dataField: 'name',
    order: 'asc'
  }];

  const selectRow = {
    mode: 'checkbox',
    clickToExpand: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect)
        setSelectedUsers([...selectedUsers, row]);
      else
        setSelectedUsers(selectedUsers.filter(u => u.user_id !== row.user_id));
    },
    onSelectAll: (isSelect, rows, e) => {
      if (isSelect)
        setSelectedUsers(rows);
      else 
        setSelectedUsers([]);
    }
  };

  useEffect(() => {
    setSaldo(userList.reduce((r, n) => r + parseFloat(n.credit), 0))
  }, [userList])

  return(
    <div id="tabInnards">
      <h3 className="p-3" align="center">
        Kokonaissaldo: {moneyFormatter(saldo)}
        </h3>
      <LazyLoad 
            height={1000} 
            placeholder={
              <div width="100%" style={{minHeight:1000}} align="center">
                <img  
                  src={scrollUpArrow} 
                  height={50} 
                  alt="Vieritä alas ladataksesi lisää sivua"
                />
              </div>
            }
          >
      <Fade duration={500}>
      <table width="100%">
        <tr width="100%">
          <td align="center">
            <CreateChargeButton spikees={selectedUsers} />
          </td>
          <td align="center">
            <SendMailButton spikees={selectedUsers} />
          </td>
          <td align="center">
            <ClearSpikesButton spikees={selectedUsers} />
          </td>
        </tr>
      </table>
        <BootstrapTable 
          id="userTable"
          keyField='user_id' 
          data={userList.filter(u => u.name !== 'Kämppä')} 
          columns={columns} 
          defaultSorted={defaultSorted} 
          selectRow={selectRow}
          expandRow={expandRow}
          bordered={false}
          className="mt-3"
          style={{backgroundColor:"whitesmoke"}}
          hover
          condensed
          bootstrap4
        />
        </Fade>
      </LazyLoad>
    </div>
  )
};

export default HostViewUsers;
