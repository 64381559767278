import {toast} from 'react-toastify';
import {sendMail} from './settingsActionCreators';


import {
  WIPE_ERROR,
  LOGIN,
  LOGOUT,
  stateNames,
  SET_TO_LOADING,
  ERROR,
  apiUrl
} from './masterReducer'


const baseUrl = apiUrl + "/auth";

export const wipeError = () => (dispatch) => {
  dispatch({type:WIPE_ERROR});
}

export const login = (info) => async (dispatch) => {
  try {
    dispatch({type:SET_TO_LOADING, setLoad: stateNames.currentUser});
    const response = await fetch(`${baseUrl}/login`, 
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body : JSON.stringify(info)
      }
    );
    const dataJson = await response.json();

    if (!dataJson.jwtToken) {
      dispatch({type: ERROR, message:'Väärä sähköpostiosoite tai salasana'});
      return;
    }

    localStorage.setItem("jwtToken", dataJson.jwtToken);
    toast.success("Kirjautuminen onnistui");
    authenticated(dispatch, dataJson.jwtToken, dataJson.isAdmin);

  } catch (err) {
    dispatch({type:ERROR, message: err.message});
  }
};

export const register = (info) => async (dispatch) => {
  try {
    dispatch({SET_TO_LOADING, setLoad: stateNames.currentUser});
    const response = await fetch(`${baseUrl}/register`, 
      {
        method: "POST",
        headers: 
          {
            "Content-Type" : "application/json"
          },
        body: JSON.stringify(info)
      });

      if (response.status === 403) {
        dispatch({type:ERROR, message: "Väärä hyshys"});
        return;
      } else if (response.status === 401) {
        dispatch({type:ERROR, message: "Nimi tai sähköpostiosoite on jo varattu"});
        return;
      }

      const dataJson = await response.json();
      console.log(dataJson)

      if (!dataJson.jwtToken) {
        dispatch({type:ERROR, message: dataJson});
        return;
      }
    
      const mails = [{
        recipient: info.email,
        content: `<h2>Moi ${info.name.split(" ")[0]}!</h2> 
        <p>Tervetuloa ePiikin käyttäjäksi! Sinut on onnistuneesti rekisteröity järjestelmään.</p>
        
        <p>Kun olet ensimmäisen kerran kirjautunut ePiikkiin, järjestelmä muistaa tunnuksesi kyseisellä selaimella
        niin kauan, kunnes manuaalisesti kirjaudut ulos. Manuaalisesti sisään kirjautuessa tarvitset
        sähköpostiosoitteesi sekä rekisteröityessä luomasi henkilökohtaisen salasanan. Salasanaasi
        ei säilytetä selkotekstinä missään järjestelmän sisällä.</p>

        <h3>ePiikin Käyttöohjeet:</h3>

        <p>Piikkaukset tehdään Piikki-näkymässä. Tuotteen nappia painamalla luot yhden piikkauksen
        kyseistä tuotetta. Voit myös piikata useamman kappaleen samaa tuotetta kerralla pitämällä
        tuotteen nappia pohjassa (tietokoneelta piikatessa hiiren oikealla näppäimellä). 
        Piikkauksen tapahduttua sinulla on 4 sekuntia aikaa perua piikkauksesi
        painamalla ruudulle ilmestyvää peruutusnappulaa. Peruutusnappulan kadottua piikkauksen voi perua
        vain Isännän tililtä tekemällä muokkauksia suoraan saldoosi.</p> 
        
        <p>Miltä tahansa käyttäjätililtä voi luoda piikkauksia kenenkä
        tahansa muun käyttäjän piikkiin vaihtamalla henkilöä näkymän ylälaidassa sijaitsevasta "Piikattava"-valikosta.
        Yhden onnistuneen piikkauksen jälkeen piikattavaksi palautuu automaattisesti kyseisen
        selaimen kirjautunut käyttäjä.</p>

        <p>Omia tietojasi, saldoasi sekä piikkauksiasi voit tarkastella
        "Omat Tiedot"-näkymästä. Tilastokuvaajat ovat interaktiivisia. Voit myös vaihtaa 
        sähköpostiosoitettasi tai salasanaasi. Käyttäjänimeä ei ole mahdollista vaihtaa.</p>

        <p>Isäntä lähettää ePiikin kautta aika ajoin käyttäjille Maksukehotteita, joista löytyy 
        maksutiedot piikkisi maksamiseen. Kun Isäntä on varmistanut sinun maksaneen piikkisi, 
        hän voi pyyhkiä piikkauksesi ePiikki-järjestelmästä. Tällöin myös tilastosi nollaantuvat.</p>
        
        <p>Mahdollisten kysymysten tai ePiikin käytön ongelmatilanteiden sattuessa, ota yhteys Isäntään tai Adminiin.</p>

        <p>Hauskaa ja vastuullista piikkailua!</p>
        `
      }];
      const title = 'Tervetuloa ePiikkiin!';
      //console.log(mails)
      localStorage.setItem("jwtToken", dataJson.jwtToken);
      dispatch(sendMail(title, mails));
      toast.success("Rekisteröinti onnistui. Sähköpostiisi pitäisi saapua varmistusviesti. Voit nyt kirjautua sisään.");
      authenticated(dispatch, dataJson.jwtToken, false);

  } catch (err) {
    dispatch({type:ERROR, message: err.message});
  }
};

export const verify = (jwtToken) => async (dispatch) => {
  try {
      if (!jwtToken) return;
    const response = await fetch(`${baseUrl}/is-verify`, 
      {
        method: "GET",
        headers: {
          'jwtToken' : jwtToken
        }
      }
    );
    const dataJson = await response.json();

    if (!dataJson.authorized) {
      return;
    }
    dispatch({type:SET_TO_LOADING, setLoad: stateNames.currentUser});
    authenticated(dispatch, jwtToken, dataJson.isAdmin);

  } catch (err) {
    dispatch({type:ERROR, message: err.message});
    return;
  }
}; 

const authenticated = async (dispatch, jwtToken, isAdmin) => {
  try {
    const response = await fetch(`${apiUrl}/dashboard`, {
        method: "GET",
        headers: 
          {
            'jwtToken' : jwtToken
          }
      }
    );
    const dataJson = await response.json();
    const loggedUser = 
      {...dataJson,
        isLogged: true,
        isAdmin: isAdmin
      };
    dispatch({type:LOGIN, currentUser: loggedUser});

  } catch (err) {
    dispatch({type:ERROR, message: err.message});
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("jwtToken");
  dispatch({type: LOGOUT});
  toast.success("Uloskirjauduttu");
}

export const send_password_reset = (email) => async (dispatch) => {
  try {

    const body = {email: email};

    const response = await fetch(`${apiUrl}/mail/forgot`, 
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body : JSON.stringify(body)
      }
    );

    if (response.status === 401) {
      dispatch({type:ERROR, message: "Sähköpostiosoitetta " + email + " ei löydy käyttäjistöstä."});
      return;
    }
    toast.success(`Linkki on lähetetty sähköpostiosoitteeseen ${email}.`);

  } catch (err) {
    dispatch({type:ERROR, message: err.message})
    return;
  }
}
